import { useState } from 'react';
import { Smile } from '../Icons/Smile';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useMessageInputContext } from 'stream-chat-react';
import { useUserStore } from '../../store/user';
import { useModalLoginStore } from '../../store/modalLogin';

export const Emoji = () => {
	const [emoji, setEmoji] = useState(false);
	const user = useUserStore((state) => state.user);
	const { openModalLogin } = useModalLoginStore();

	const { insertText, textareaRef } = useMessageInputContext();

	return (
		<div className="flex items-center gap-4">
			<div
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					if (user) {
						setEmoji(!emoji);
					} else {
						openModalLogin(true, '');
					}
				}}
				className=" cursor-pointer w-4 h-4 mr-1 -mt-0.5"
			>
				<Smile />
			</div>

			<div
				id="emoji"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					if (user) {
						setEmoji(!emoji);
					} else {
						openModalLogin(true, '');
					}
				}}
				className={`fixed bottom-16 right-2 z-50 ${
					emoji ? 'visible' : 'invisible'
				}`}
			>
				<Picker
					locale="pt"
					theme="light"
					emojiButtonSize="28"
					emojiSize="16"
					searchPosition="none"
					previewPosition="none"
					perLine={12}
					onEmojiSelect={(e: any) => {
						insertText(e.native);
						textareaRef.current?.focus();
						setEmoji(!emoji);
					}}
					data={data}
				/>
			</div>
		</div>
	);
};
