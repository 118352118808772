export type variantProp =
	| ''
	| 'destructive'
	| 'help'
	| 'small'
	| 'cancel'
	| 'white'
	| 'white2'
	| 'black'
	| 'delete'
	| 'disabled'
	| 'hidden';

export const getVariant = (style: variantProp) => {
	switch (style) {
		case 'disabled':
			return 'flex justify-center items-center relative gap-2.5 rounded-lg bg-[#9b9b9b] text-sm font-semibold text-center text-custom-white';
		case 'destructive':
			return 'flex justify-center items-center relative gap-2.5 rounded-lg bg-custom-gray text-sm font-semibold text-center text-primary';
		case 'help':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-custom-gray';
		case 'small':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-primary/10 text-primary';
		case 'cancel':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-error';
		case 'white':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-custom-gray';
		case 'white2':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-[#FEF9EC]';
		case 'delete':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-[#ececec] py-1.5 px-3';
		case 'black':
			return 'flex justify-center items-center relative rounded-[6.22px] bg-custom-black';
		case 'hidden':
			return 'flex justify-center items-center relative rounded-[6.22px] border border-black';
		default:
			return 'flex justify-center items-center relative gap-2.5 rounded-lg bg-primary text-sm font-semibold text-center text-custom-white';
	}
};

export type variantToastProp = 'success' | 'warning' | 'error' | 'info';

export const getVariantToast = (style: variantToastProp) => {
	switch (style) {
		case 'warning':
			return 'flex py-3 px-4 justify-center items-center rounded-lg bg-warning lg:w-full lg:max-w-screen-xl';
		case 'info':
			return 'flex py-3 px-4 justify-center items-center rounded-lg bg-info lg:w-full lg:max-w-screen-xl';
		case 'error':
			return 'flex py-3 px-4 justify-center items-center rounded-lg bg-error lg:w-full lg:max-w-screen-xl';
		case 'success':
			return 'flex py-3 px-4 justify-center items-center rounded-lg bg-success lg:w-full lg:max-w-screen-xl';
		default:
			return 'flex py-3 px-4 justify-center items-center rounded-lg bg-success lg:w-full lg:max-w-screen-xl';
	}
};
