import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/capacitor';
import './styles/globals.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IonContent, IonPage } from '@ionic/react';

Sentry.init({
	dsn: 'https://5685984283d9f27a33ac90c41ad0552b@o4507194284834816.ingest.us.sentry.io/4507209559834624',
	integrations: [],
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<IonPage className="overflow-hidden">
			<IonContent>
				<App />
			</IonContent>
		</IonPage>
	</React.StrictMode>
);

reportWebVitals();
