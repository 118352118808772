import { useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout';
import Button from '../../components/ui/button';
import { FutProIcon } from '../../components/Icons/FutProIcon';

export const Error404 = () => {
	const navigate = useNavigate();
	return (
		<Layout>
			<div className="w-full h-full flex flex-col justify-between items-center px-6 mt-40 gap-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="flex flex-row justify-center items-center">
					<div className="text-center text-primary text-9xl font-bold font-bahnschrift">
						4
					</div>
					<div className="">
						<FutProIcon style="w-28 h-28" color={'#DF1737'} />
					</div>
					<div className="text-center text-primary text-9xl font-bold font-bahnschrift">
						4
					</div>
				</div>

				<div className="w-80 text-center mb-16">
					<span className="text-neutral-800 text-base font-semibold font-bahnschrift leading-normal">
						Ops! Parece que você se perdeu.
					</span>
					<span className="text-neutral-800 text-base font-normal font-bahnschrift">
						{' '}
						<br />
						Explore nosso universo de jogos ou retorne à página
						inicial para encontrar o caminho certo.
					</span>
				</div>

				<Button
					onClick={() => {
						navigate('/');
					}}
					className="w-full py-3.5"
				>
					Voltar para página inicial
				</Button>
			</div>
		</Layout>
	);
};
