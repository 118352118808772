export enum SentryError {
	Fatal = 'fatal',
	Error = 'error',
	Warning = 'warning',
	Log = 'log',
	Info = 'info',
	Debug = 'debug',
}

export const permissionRoles = [1];

export enum FinanceStatusType {
	CANCELED = 0,
	PROVISSIONED = 1,
	EXECUTED = 2,
	EXPIRED = 3,
}

export enum FinanceOperationType {
	OTHER = 0,
	DEPOSIT = 1,
	WITHDRAW = 2,
	BETMADE = 3,
	BETWIN = 4,
	BETEXTRAWIN = 5,
}

export enum ContentTypes {
	TOURNAMENT = 0,
	ADS = 1,
	NEWS = 2,
	SCOREBOARD = 3,
}

export enum ClickActionType {
	NOTHING = 0,
	DIALOG = 1,
	NAVIGATE = 2,
}

export enum ChannelTypes {
	HOMEHEAD = 0,
	HOMEBODY = 1,
}

export enum FinanceOperationDisplayType {
	'Outros' = 0,
	'Depósito' = 1,
	'Saque' = 2,
	'Torneio' = 3,
	'Cred.Prêmio' = 4,
	'Cred.Prêmio Extra' = 5,
}

export enum ChannelTypesDisplay {
	'Header' = 0,
	'Body' = 1,
}

export enum ContentTypesDisplay {
	'Torneio' = 0,
	'Publicidade' = 1,
	'Noticias' = 2,
	'Classificação' = 3,
}

export enum TournamentOptionType {
	STATEMENT = 0,
	DRAFT = 1,
	NUMBER = 2,
}

export enum TournamentOptionTypeDisplay {
	'Afirmação' = 0,
	'Fantasy' = 1,
	'Numero' = 2,
}

export enum PlayersPositionDisplay {
	'Goleiro' = 0,
	'Lateral' = 1,
	'Zagueiro' = 2,
	'Meio Campo' = 3,
	'Atacante' = 4,
}

export enum TournamentTypeDisplay {
	'Inscrições aberta' = 0,
	'Em Apuração' = 1,
	'Cancelado' = 2,
	'Finalizado' = 3,
}
