import { ptBR } from 'date-fns/locale/pt-BR';
import ModalDialog from '../../Modal/Dialog';
import { format } from 'date-fns';
import { useState } from 'react';

export const Games = ({ eventsTournament }: any) => {
	const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
	return (
		<div className="grid gap-4 lg:grid-cols-3">
			{eventsTournament.map((event: any, index: number) => (
				<div
					onClick={() => {
						if (event.event?.result === '') {
							return;
						}

						if (event.scores.length === 0) {
							return;
						}

						setOpenModalIndex(
							openModalIndex === event.id ? null : event.id
						);
					}}
					className="flex flex-col"
					key={index}
				>
					<div className="flex-row h-[38px] rounded-t-lg px-4 py-2 bg-primary justify-between items-center inline-flex">
						<div className="text-custom-white text-xs font-bold font-bahnschrift leading-snug">
							{event.event.category.name}
						</div>
						<div className="text-custom-white text-xs font-bold font-bahnschrift leading-snug">
							{format(
								event.event.startAt.replace('Z', ''),
								"dd/MM • EEE • HH'h'mm",
								{ locale: ptBR }
							)}
						</div>
					</div>

					<div className="px-4 py-2 bg-gray-100 rounded-b-lg flex-col gap-px inline-flex">
						<div className="grid grid-flow-col justify-between">
							<div className="flex flex-row justify-start w-28">
								<div className="flex flex-row items-center gap-2">
									<img
										className="w-10 h-10 object-scale-down"
										src={event.teamHome.image}
										alt={event.teamHome.name}
									/>
									<span className="text-custom-black text-left text-xs font-bold font-bahnschrift leading-snug">
										{event.teamHome.name}
									</span>
								</div>
							</div>

							<div className="flex flex-row justify-center items-center gap-1 w-16">
								<div className="text-[#818181] text-2xl font-bold font-bahnschrift leading-snug">
									{event.event.resultStatus === 2 &&
										event.teamHome.score}
								</div>
								<div className="text-[#818181] text-xs font-bold font-bahnschrift leading-snug">
									x
								</div>
								<div className="text-[#818181] text-2xl font-bold font-bahnschrift leading-snug">
									{event.event.resultStatus === 2 &&
										event.teamAway.score}
								</div>
							</div>

							<div className="flex flex-row justify-end w-28">
								<div className="flex flex-row items-center gap-2">
									<span className="text-custom-black text-right text-xs font-bold font-bahnschrift leading-snug">
										{event.teamAway.name}
									</span>
									<img
										className="w-10 h-10 object-scale-down"
										src={event.teamAway.image}
										alt={event.teamAway.name}
									/>
								</div>
							</div>
						</div>
						{event.event.resultStatus === 2 &&
							event.event.result !== '0x0' &&
							event.scores.length > 0 && (
								<div
									onClick={() => {
										if (event.event?.result === '') return;
										setOpenModalIndex(
											openModalIndex === event.id
												? null
												: event.id
										);
									}}
									className="text-[#0c67a0] text-xs text-center font-normal font-bahnschrift underline cursor-pointer"
								>
									Ver Gols
								</div>
							)}
						<ModalDialog
							id={event.id}
							open={openModalIndex === event.id}
							onClose={() => setOpenModalIndex(null)}
						>
							<div className="w-full px-6 pb-6 bg-white rounded-lg flex-col justify-center items-end gap-6 inline-flex">
								{event.teamHome.score !== 0 && (
									<div className="self-stretch p-4 bg-custom-white1 rounded-lg flex-col justify-center items-center gap-4 flex">
										<div className="self-stretch pb-2.5 border-b border-[#c1c1c1] justify-start items-center gap-2.5 inline-flex">
											<div className="rounded-[61px] justify-center items-center gap-2.5 flex">
												<div className="justify-start items-center gap-2.5 flex">
													<img
														className="w-8 h-8 object-scale-down"
														src={
															event.teamHome.image
														}
														alt={
															event.teamHome.name
														}
													/>
													<div className="text-black text-base font-normal font-bahnschrift leading-snug">
														{event.teamHome.name}
													</div>
												</div>
											</div>
										</div>
										<div className="self-stretch flex-col justify-start items-start gap-2 flex">
											{event.scores &&
												event.scores
													.filter(
														(score: any) =>
															score.team.name ===
															event.teamHome.name
													)
													.map(
														(
															score: any,
															index: number
														) => (
															<div
																key={index}
																className="self-stretch rounded justify-between items-center inline-flex"
															>
																<div className="justify-start items-center gap-4 flex">
																	<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																		<div className="justify-start items-center inline-flex">
																			<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																				{
																					score.playerName
																				}
																			</div>
																		</div>
																	</div>
																</div>
																<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																	<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																		{`${
																			score.score
																		} ${
																			Number(
																				score.score
																			) ===
																			1
																				? score.scoutName
																				: score.scoutNameSuffix
																		}`}
																	</div>
																</div>
															</div>
														)
													)}
										</div>
									</div>
								)}

								{event.teamAway.score !== 0 && (
									<div className="self-stretch p-4 bg-custom-white1 rounded-lg flex-col justify-center items-center gap-4 flex">
										<div className="self-stretch pb-2.5 border-b border-[#c1c1c1] justify-start items-center gap-2.5 inline-flex">
											<div className="rounded-[61px] justify-center items-center gap-2.5 flex">
												<div className="justify-start items-center gap-2.5 flex">
													<img
														className="w-8 h-8 object-scale-down"
														src={
															event.teamAway.image
														}
														alt={
															event.teamAway.name
														}
													/>
													<div className="text-black text-base font-normal font-bahnschrift leading-snug">
														{event.teamAway.name}
													</div>
												</div>
											</div>
										</div>
										<div className="self-stretch flex-col justify-start items-start gap-2 flex">
											{event.scores &&
												event.scores
													.filter(
														(score: any) =>
															score.team.name ===
															event.teamAway.name
													)
													.map(
														(
															score: any,
															index: number
														) => (
															<div
																key={index}
																className="self-stretch rounded justify-between items-center inline-flex"
															>
																<div className="justify-start items-center gap-4 flex">
																	<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																		<div className="justify-start items-center inline-flex">
																			<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																				{
																					score.playerName
																				}
																			</div>
																		</div>
																	</div>
																</div>
																<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																	<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																		{`${
																			score.score
																		} ${
																			Number(
																				score.score
																			) ===
																			1
																				? score.scoutName
																				: score.scoutNameSuffix
																		}`}
																	</div>
																</div>
															</div>
														)
													)}
										</div>
									</div>
								)}
							</div>
						</ModalDialog>
					</div>
				</div>
			))}
		</div>
	);
};
