import { Clipboard } from '@capacitor/clipboard';
import { toast } from 'sonner';
import Toast from '../components/ui/toast';
import {
	RiFacebookFill,
	RiInstagramLine,
	RiLinkedinFill,
	RiTwitterXFill,
	RiWhatsappLine,
} from '@remixicon/react';

export const copyToClipboard = async (data: any) => {
	try {
		await Clipboard.write({ string: data });
		toast(<Toast variant="success">Copiado com sucesso!</Toast>);
	} catch (err) {
		console.log('Falha ao copiar o texto', err);
		toast(<Toast variant="error">Falha ao copiar.</Toast>);
	}
};

export const shared = [
	{
		title: 'Linkedin',
		icon: <RiLinkedinFill className="text-[#4b4b4b]" />,
	},
	{
		title: 'Instagram',
		icon: <RiInstagramLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'Whatsapp',
		icon: <RiWhatsappLine className="text-[#4b4b4b]" />,
	},
	{
		title: 'Facebook',
		icon: <RiFacebookFill className="text-[#4b4b4b]" />,
	},
	{
		title: 'X',
		icon: <RiTwitterXFill className="text-[#4b4b4b]" />,
	},
];
