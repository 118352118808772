import { useEffect } from 'react';
import { CurrencyFormatter } from '../../../helpers/utils';
import { Warning } from '../../Icons/Warning';
import Button from '../../ui/button';
import Modal from '../modal';

export const WithdrawPixSuccess = ({ open, onClose, value }: any) => {
	useEffect(() => {
		if (open) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}

		return () => document.body.classList.remove('overflow-hidden');
	}, [open]);

	return (
		<Modal
			id="WithdrawPixSuccess"
			open={open}
			onClose={() => {
				onClose();
			}}
		>
			<div className="flex flex-col gap-6 items-center">
				<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
					Saque Pix
				</p>
				<p className="w-11/12 text-sm text-center text-custom-gray2 font-bahnschrift">
					Saque pix de R$ {CurrencyFormatter(Number(value))} executado
					e creditado na c/c do seu banco
				</p>

				<div className="w-full pt-2">
					<Button
						onClick={() => {
							onClose();
						}}
						className={'w-full h-12'}
					>
						Fechar
					</Button>
				</div>
			</div>
		</Modal>
	);
};
