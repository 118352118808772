import { RiChat1Fill, RiChat1Line, RiCloseLine } from '@remixicon/react';
import { useEffect } from 'react';

type ModalProps = {
	id: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
};

const ModalChat = ({ open, onClose, children, id }: ModalProps) => {
	useEffect(() => {
		if (open) {
			document.body.classList.add('overflow-hidden');
			document.getElementById('launcher')?.classList.add('hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
			document.getElementById('launcher')?.classList.remove('hidden');
		}

		return () => {
			document.body.classList.remove('overflow-hidden');
			document.getElementById('launcher')?.classList.remove('hidden');
		};
	}, [open]);

	return (
		<div
			id={id}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			className={`fixed inset-0 flex justify-center items-end transition-colors z-[45] ${
				open ? 'visible bg-custom-gray1/70' : 'invisible'
			}`}
		>
			<div
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
				className={`w-full lg:max-w-screen-xl overflow-hidden bg-[#e3e3e3] shadow transition-all ${
					open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
				}`}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
					}}
					className="h-9 w-full px-4 py-2 bg-[#181818] justify-between items-center inline-flex"
				>
					<div
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						className="h-5 justify-start items-center gap-1 flex"
					>
						<RiChat1Line className="w-4 h-4 text-primary" />
						<div className="flex-col justify-start items-start gap-1 inline-flex">
							<div className="text-center text-primary text-sm font-semibold font-bahnschrift leading-tight tracking-tight">
								Chat ao vivo
							</div>
						</div>
					</div>
					<div
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							onClose();
						}}
						className="absolute cursor-pointer top-2 right-2 p-1 rounded-lg text-gray-400 hover:bg-gray-50 hover:text-gray-600"
					>
						<RiCloseLine className="w-4 h-4 text-white" />
					</div>
				</div>

				{children}
			</div>
		</div>
	);
};

export default ModalChat;
