import {
	RiArrowLeftSLine,
	RiCustomerServiceFill,
	RiMailLine,
	RiPhoneFill,
	RiTimeFill,
} from '@remixicon/react';
import Footer from '../../components/Footer';
import { Layout } from '../../components/layout';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/button';
import Accordion from '../../components/ui/accordion';
import ButtonBack from '../../components/ui/buttonBack';

declare global {
	interface Window {
		zE?: any;
	}
}

export const Help = () => {
	const items = [
		{
			title: 'Quanto tempo leva para processar um saque?',
			content: 'Conteúdo do Item 3',
		},
		{
			title: 'Quais são as regras do quiz?',
			content: 'Conteúdo do Item 2',
		},
		{
			title: 'Como posso realizar um depósito na minha conta de jogador?',
			content:
				'Oferecemos diversas opções seguras de pagamento. Para depositar, acesse a seção "Minha Conta" e escolha a opção de depósito que melhor se adequa às suas necessidades.',
		},
		{
			title: 'Como posso realizar um depósito na minha conta de jogador?',
			content: 'Conteúdo do Item 3',
		},
	];

	return (
		<Layout customBG="bg-[#0C0C0D]" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-6 px-3.5">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-custom-white font-bahnschrift">
						Suporte
					</p>
					<div></div>
				</div>

				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-3">
						<p className="text-2xl font-semibold text-left text-custom-white font-bahnschrift pb-5">
							Perguntas Frequentes
						</p>

						<Accordion items={items} />
					</div>

					<div className="flex flex-col gap-3">
						<p className="text-2xl font-semibold text-left text-custom-white font-bahnschrift pb-5">
							Informações de contato
						</p>

						<div className="flex flex-col gap-6">
							<a
								href="mailto:suporte@futpro.com.br"
								className="flex flex-row items-center"
							>
								<RiMailLine
									size={20}
									color="#DF1737"
									className="mr-2"
								/>
								<p className="text-sm font-semibold text-left text-custom-white font-bahnschrift">
									suporte@futpro.com.br
								</p>
							</a>
							<a
								href="tel:+5571999999999"
								className="flex flex-row items-center"
							>
								<RiPhoneFill
									size={20}
									color="#DF1737"
									className="mr-2"
								/>
								<p className="text-sm font-semibold font-bahnschrift text-left text-custom-white">
									+55 71 9 9999-9999
								</p>
							</a>
							<div className="flex flex-row items-center">
								<RiTimeFill
									size={20}
									color="#DF1737"
									className="mr-2"
								/>
								<p className="text-sm font-semibold text-left text-custom-white font-bahnschrift">
									Seg a Sex: 8h às 23h | Sab e Dom: 10h às 22h
								</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-3">
						<p className="text-2xl font-semibold text-left text-custom-white pb-5 font-bahnschrift">
							Suporte ao vivo
						</p>

						<div className="flex flex-col justify-start items-start gap-6 p-6 rounded-lg bg-[#121416]">
							<Button
								// onClick={() => window.zE('messenger', 'open')}
								onClick={() => window.zE('webWidget', 'open')}
								className="px-3.5 py-3"
							>
								<RiCustomerServiceFill
									size={20}
									color="#FFFFFF"
									className="mr-2"
								/>
								Suporte ao vivo
							</Button>
							<div className="flex flex-row items-center">
								<RiTimeFill
									size={20}
									color="#A3A5AB"
									className="mr-2"
								/>
								<p className="text-xs text-left text-custom-gray3 font-bahnschrift">
									Seg a Sex: 8h às 23h | Sab e Dom: 10h às 22h
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</Layout>
	);
};
