import { useEffect, useState } from 'react';

import Button from '../ui/button';
import Modal from '../Modal/modal';
import LoginForms from '../Forms/Modal/Login';
import RegisterForms from '../Forms/Modal/Register';
import { useModalLoginStore } from '../../store/modalLogin';
import LoginTokenForms from '../Forms/Modal/LoginToken';
import ForgotPassword from '../Forms/Modal/ForgotPassword';
import { useLocation } from 'react-router-dom';

const LoginAndRegister = () => {
	const location = useLocation();
	const loginAction = useModalLoginStore((state) => state.modalLogin);
	const loginUrlAction = useModalLoginStore((state) => state.loginUrlAction);
	const { closeModalLogin } = useModalLoginStore();
	const [openLogin, setOpenLogin] = useState<boolean>(loginAction);
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [openLoginToken, setLoginTokenModal] = useState<boolean>(false);
	const [openForgotPass, setForgotPassModal] = useState<boolean>(false);

	const [emailTokenLogin, setEmailTokenLogin] = useState<string>('');

	useEffect(() => {
		if (
			location.pathname === '/termsOfLegalAge' ||
			location.pathname === '/privacypolicy' ||
			location.pathname === '/termsofuse'
		)
			return;

		const shouldOpenModal = localStorage.getItem('goToTerms');
		if (shouldOpenModal) {
			setOpenRegister(true);
			localStorage.removeItem('goToTerms');
		}
	}, []);

	useEffect(() => {
		if (loginAction) {
			setOpenLogin(!openLogin);
		}
	}, [loginAction]);

	const callBackRegisterAndLoginOpen = () => {
		setOpenLogin(!openLogin);
		setOpenRegister(!openRegister);
	};

	const callBackForgotPassOpen = () => {
		setOpenLogin(!openLogin);
		setForgotPassModal(!openForgotPass);
	};

	const callBackRegisterAndLoginTokenOpen = (navToLoginToken: string) => {
		setOpenLogin(!openLogin);
		setLoginTokenModal(!openLoginToken);
		setEmailTokenLogin(navToLoginToken);
	};

	const closeLoginModal = () => {
		setOpenLogin(!openLogin);
		closeModalLogin();
	};

	const closeRegisterModal = () => {
		setOpenRegister(!openRegister);
		closeModalLogin();
	};

	const closeForgotPasswordModal = () => {
		setForgotPassModal(!openForgotPass);
		closeModalLogin();
	};

	const closeLoginTokenModal = () => {
		setEmailTokenLogin('');
		setLoginTokenModal(!openLoginToken);
		closeModalLogin();
	};

	return (
		<div className="flex flex-row gap-2">
			<Button
				onClick={() => setOpenLogin(!openLogin)}
				className="px-3.5 py-1.5 max-[320px]:text-xs"
				variant="destructive"
			>
				Entrar
			</Button>

			<Button
				onClick={() => setOpenRegister(!openRegister)}
				className="px-3.5 py-1.5 max-[320px]:text-xs max-[320px]:px-2"
			>
				Cadastre-se
			</Button>

			<Modal id="login" open={openLogin} onClose={closeLoginModal}>
				<LoginForms
					loginUrlAction={loginUrlAction}
					navToRegister={callBackRegisterAndLoginOpen}
					navToLoginToken={callBackRegisterAndLoginTokenOpen}
					navToForgotPass={callBackForgotPassOpen}
					onClose={closeLoginModal}
				/>
			</Modal>

			<Modal
				id="loginToken"
				open={openLoginToken}
				onClose={closeLoginTokenModal}
			>
				<LoginTokenForms
					loginUrlAction={loginUrlAction}
					openModal={openLoginToken}
					email={emailTokenLogin}
					navToRegister={callBackRegisterAndLoginOpen}
					onClose={closeLoginTokenModal}
				/>
			</Modal>

			<Modal
				id="ForgotPassword"
				open={openForgotPass}
				onClose={closeForgotPasswordModal}
			>
				<ForgotPassword onClose={closeForgotPasswordModal} />
			</Modal>

			<Modal
				id="register"
				open={openRegister}
				onClose={closeRegisterModal}
			>
				<RegisterForms
					openModal={openRegister}
					onClose={closeRegisterModal}
				/>
			</Modal>
		</div>
	);
};

export default LoginAndRegister;
