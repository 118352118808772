interface IProps {
	style: string;
	color: string;
}

export const FutProIcon = ({ style, color }: IProps) => {
	return (
		<svg
			width="638"
			height="616"
			viewBox="0 0 638 616"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={style}
		>
			<path
				d="M319 0.655273C143.119 0.655273 0 138.546 0 308C0 477.455 143.119 615.345 319 615.345C494.881 615.345 638 477.455 638 308C638 138.546 494.881 0.655273 319 0.655273ZM455.472 454.909L403.108 386.16L435.327 291.466L518.553 267.025L596.964 320.809C594.522 370.214 578.175 416.09 551.586 455.04H455.54L455.472 454.909ZM183.138 454.778L86.482 455.04C60.0287 416.352 43.6141 370.737 41.0366 321.659L119.922 267.744L202.198 291.924L235.706 386.16L183.071 454.713L183.138 454.778ZM92.5188 238.467L43.2749 272.123C47.8195 239.447 58.4008 208.667 74.0693 180.827L92.5188 238.467ZM273.012 370.018L242.285 283.624L319 231.017L395.308 283.298L365.802 370.018H273.012ZM546.024 237.748L564.202 181.35C579.531 208.732 589.977 239.055 594.59 271.077L546.024 237.748ZM535.103 139.33L506.005 229.775L422.982 254.151L339.756 197.1V112.863L418.505 57.6412C464.493 74.6324 504.58 103.06 535.103 139.33ZM368.515 44.1789L319.339 78.6842L269.553 44.1789C285.628 41.3688 302.111 39.8658 319.068 39.8658C336.025 39.8658 352.508 41.4342 368.583 44.1789H368.515ZM219.291 57.7065L298.99 112.928V196.577L214.408 254.609L132.538 230.559L103.168 138.872C133.623 102.864 173.575 74.6324 219.291 57.7065ZM119.04 494.185L178.73 493.989L197.79 549.275C168.216 535.421 141.627 516.665 119.04 494.119V494.185ZM246.762 566.92L216.036 477.847L268.739 409.229H370.279L422.575 477.978L392.188 566.659C368.854 572.802 344.368 576.135 319 576.135C293.632 576.135 269.824 572.867 246.762 566.92ZM441.092 548.883L459.881 494.185H518.96C496.577 516.535 470.327 535.094 441.092 548.883Z"
				fill={color}
			/>
		</svg>
	);
};
