import { useCallback, useEffect, useRef, useState } from 'react';
import ModalChat from '../Modal/modalChat';
import { Send } from '../Icons/Send';
import { useUserProfileStore, useUserStore } from '../../store/user';
import { useModalLoginStore } from '../../store/modalLogin';
import {
	Channel,
	ChannelHeaderProps,
	Chat,
	MessageInput,
	useChannelStateContext,
	useCreateChatClient,
	useMessageContext,
	useMessageInputContext,
	VirtualizedMessageList,
	Window,
} from 'stream-chat-react';
import { type User, type Channel as StreamChannel } from 'stream-chat';
import { useApi } from '../../hooks/useApi';
import { Emoji } from '../Emoji';

type LiveChatProps = {
	id: string;
	tournamentId: string;
	token: string | null;
};
const apiKey = 'efe24uu7hbbc';

export const LiveChat = ({ id, tournamentId, token }: LiveChatProps) => {
	const [channel, setChannel] = useState<StreamChannel | null>(null);
	const inputRef = useRef<any>(null);
	const api = useApi();
	const [open, setOpen] = useState(false);
	const chatContainerRef = useRef<HTMLDivElement | null>(null);
	const userInfo = useUserProfileStore((state: any) => state.userData);
	const user = useUserStore((state) => state.user);
	const { openModalLogin } = useModalLoginStore();

	const tokenProvider = useCallback(async () => {
		const response = await api.getTokenFeed();
		if (!response?.success) {
			throw new Error(response.message);
		}
		const { token } = response;
		return token;
	}, []);

	const client = useCreateChatClient({
		apiKey,
		tokenOrProvider: token ? token : tokenProvider,
		userData: {
			id: user ? user?.accountId : 'anonymous',
			name: user ? userInfo.nick : 'anonymous',
			image: `https://getstream.io/random_png/?name=${
				user ? userInfo.nick : 'anonymous'
			}`,
			language: 'pt',
		},
	});

	useEffect(() => {
		const initChat = async () => {
			if (client) {
				const channel = client.channel('chatTournament', tournamentId, {
					image: 'https://getstream.io/random_png/?name=react',
					name: 'Torneio',
				});
				channel.watch();
				setChannel(channel);
			}
		};

		initChat();

		return () => {
			setChannel(null);
			client?.disconnectUser();
		};
	}, [client]);

	useEffect(() => {
		const chatContainer = chatContainerRef.current;
		if (chatContainer) {
			chatContainer.scrollTop = chatContainer.scrollHeight;
		}
	}, [channel?.id]);

	const CustomMessage = () => {
		const { message } = useMessageContext();

		return (
			<div className="self-stretch justify-start items-center gap-4 inline-flex">
				<div className="text-center text-black text-xs font-semibold font-bahnschrift leading-none tracking-tight">
					{message.user?.name}
				</div>
				<div className="text-center text-black text-xs font-light font-bahnschrift leading-none tracking-tight">
					{message.text ===
					'Message was blocked by moderation policies'
						? 'A mensagem foi bloqueada pelas políticas de moderação!'
						: message.text}
				</div>
			</div>
		);
	};

	const CustomInput = () => {
		const { text, handleChange, handleSubmit } = useMessageInputContext();
		const handleKeyPress = (event: any) => {
			if (!user) {
				openModalLogin(true, '');
				return;
			}
			if (event.key === 'Enter') {
				handleSubmit();
			}
		};
		return (
			<div className="w-full h-16 px-4 pt-2 bg-[#e3e3e3] border-t border-[#9b9b9b] flex-col justify-center items-center inline-flex">
				<div className="self-stretch grow shrink basis-0 pt-0.5 justify-center items-center gap-2.5 inline-flex">
					<div className="grow shrink basis-0 h-10 pl-3 pr-1.5 py-0.5 bg-white rounded-2xl border border-[#c9c4bb] justify-between items-center flex">
						<textarea
							ref={inputRef}
							className="w-full h-4 text-left overflow-hidden text-black text-xs font-light font-bahnschrift leading-none tracking-tight outline-none resize-none"
							placeholder="Digite aqui sua mensagem..."
							value={text}
							onChange={handleChange}
							onKeyDown={handleKeyPress}
						/>

						<Emoji />
					</div>

					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleSubmit();
							if (inputRef?.current) {
								inputRef?.current?.focus();
							}
						}}
						className="w-4 h-4 cursor-pointer"
					>
						<Send />
					</div>
				</div>

				<div className="grow shrink basis-0 px-32 py-2 flex-col justify-end items-center gap-2 flex"></div>
			</div>
		);
	};

	const CustomHeader = (props: ChannelHeaderProps) => {
		const { watcher_count } = useChannelStateContext();

		return (
			<div className="w-full h-8 px-4 py-2 bg-[#d0d0d0] justify-start items-center gap-1 inline-flex">
				<div className="flex-col justify-start items-start gap-1 inline-flex">
					<div className="text-center text-[#484848] text-xs font-bahnschrift leading-none tracking-tight">
						{watcher_count}{' '}
						{watcher_count && watcher_count > 1
							? 'participantes'
							: 'participante'}{' '}
						online
					</div>
				</div>
			</div>
		);
	};

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			className="flex items-center gap-4"
		>
			<div
				onClick={() => {
					setOpen(!open);
				}}
				className="w-full p-2.5 bg-neutral-300 rounded-lg border bg-[#D4D4D4] border-stone-300 flex-col justify-start items-start gap-3 flex"
			>
				<div className="self-stretch flex-row justify-between items-start gap-1 flex">
					<div className="text-center text-[#484848] text-sm font-semibold font-bahnschrift leading-tight tracking-tight">
						Chat ao vivo
					</div>

					{/* {client && (
						<div className="rounded-3xl px-2 py-1 bg-[#b02a37] text-white text-xs font-semibold font-bahnschrift leading-3">
							10
						</div>
					)} */}
				</div>

				<div className="self-stretch px-2 py-1 bg-stone-300 rounded-3xl justify-start items-center gap-2.5 inline-flex">
					<div className="text-center text-neutral-700 text-xs font-bahnschrift leading-none tracking-tight">
						Clique aqui e participe do chat (Em fase de
						implementação)
					</div>
				</div>
			</div>

			<ModalChat id={id} open={open} onClose={() => setOpen(!open)}>
				{!client ? (
					<div className="w-full h-full">
						<div>Aguarde estamos conectado você ao chat...</div>
					</div>
				) : (
					<div
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
						className="w-full h-full overflow-y-auto relative"
					>
						<Chat client={client} defaultLanguage="pt">
							<Channel channel={channel!} Input={CustomInput}>
								<Window>
									<CustomHeader />
									<div
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
										className="w-full h-80"
									>
										<VirtualizedMessageList
											hideDeletedMessages
											scrollToLatestMessageOnFocus
											hideNewMessageSeparator
											disableDateSeparator
											showUnreadNotificationAlways
											Message={CustomMessage}
										/>
									</div>

									<MessageInput />
								</Window>
							</Channel>
						</Chat>
					</div>
				)}
			</ModalChat>
		</div>
	);
};
