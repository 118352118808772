import { useState, memo, forwardRef } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { Checked } from '../../../../../components/Icons/Checked';
import { Unchecked } from '../../../../../components/Icons/Unchecked';
import ModalDialog from '../../../../../components/Modal/Dialog';

interface OptionItemProps {
	item: any;
	index: number;
	status: any;
}

const OptionItemView = memo(
	forwardRef<HTMLDivElement, OptionItemProps>(
		({ item, index, status }, ref) => {
			const [openModalIndex, setOpenModalIndex] = useState(false);
			const renderOption = (optionType: number) => {
				switch (optionType) {
					case 0:
						return (
							<div className="w-full flex flex-col gap-2">
								{item.resultStatus === 1 ? (
									<div className="justify-between items-center gap-2 inline-flex">
										<div
											className={
												'w-20 h-7 p-2  rounded-lg justify-center items-center gap-2 inline-flex ' +
												(item.statementResult
													? 'bg-[#0ea25d]'
													: 'bg-[#a20e0e]')
											}
										>
											<div className="text-white text-xs font-normal font-bahnschrift leading-3">
												{item.statementResult
													? 'Verdadeiro'
													: 'Falso'}
											</div>
										</div>
										<div
											className={`flex gap-2 justify-start items-center px-4 py-2 rounded-[68px] transition-transform duration-300 ${
												item.statementResult
													? 'bg-[#cccccc] scale-110'
													: 'scale-100'
											}`}
										>
											<div className="flex justify-start items-center gap-2">
												{item.statementResult ? (
													<Checked />
												) : (
													<Unchecked />
												)}
												<div className="text-custom-black text-sm font-semibold font-bahnschrift">
													Sim
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="justify-between items-center gap-2 inline-flex">
										<div className="w-20 h-7 p-2  rounded-lg justify-center items-center gap-2 inline-flex bg-[#ea8d27]">
											<div className="text-white text-xs font-normal font-bahnschrift leading-3">
												Pendente
											</div>
										</div>
									</div>
								)}
							</div>
						);
					case 1:
						return (
							<div className="flex flex-col justify-between gap-[5px]">
								{status === 1 || status === 3 ? (
									Array.isArray(item.draftResult) &&
									item.draftResult.length > 0 ? (
										Array.isArray(item.draftResult[0]) ? (
											<>
												<div
													onClick={() => {
														setOpenModalIndex(
															!openModalIndex
														);
													}}
													className="text-[#0a63e8] text-xs font-semibold font-bahnschrift underline cursor-pointer"
												>
													Mais de uma escalação
													vencedora
												</div>
												<ModalDialog
													id={'modalDraftResult'}
													open={openModalIndex}
													title="Escalações vencedoras"
													onClose={() =>
														setOpenModalIndex(
															!openModalIndex
														)
													}
												>
													<div
														key={index}
														className="px-6 pb-6 flex flex-col gap-4 overflow-y-scroll max-h-[75vh] mb-4"
													>
														{item.draftResult.map(
															(
																group: any,
																i: number
															) => {
																const title =
																	group[0]
																		?.league &&
																	group[0]
																		?.league
																		.name;

																return (
																	<div
																		key={i}
																		className="flex flex-col gap-[5px] p-4 rounded-lg bg-[#ececec]"
																	>
																		<div className="text-black text-base font-normal font-bahnschrift leading-[14px] mb-2">
																			Liga{' '}
																			{
																				title
																			}
																		</div>
																		{group.map(
																			(
																				scout: any,
																				i: number
																			) => (
																				<div
																					key={
																						i
																					}
																					className="w-full h-[47px] p-[5px] bg-[#d2d2d2] rounded flex flex-row justify-between items-center"
																				>
																					<div className="justify-start items-center gap-4 flex">
																						<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																							<div className="justify-start items-center inline-flex">
																								<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																									{
																										scout.playerName
																									}
																								</div>
																							</div>
																							<div className="justify-start items-center gap-1 inline-flex">
																								<img
																									className="w-4 h-4"
																									src={
																										scout
																											.team
																											.image
																									}
																									alt={
																										scout
																											.team
																											.name
																									}
																								/>
																								<div className="text-black text-xs font-light font-bahnschrift leading-[14px]">
																									{
																										scout
																											.team
																											.name
																									}
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																						<div className="justify-start items-center inline-flex">
																							<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																								{`${
																									scout.score
																								} ${
																									scout.score <=
																									1
																										? scout.scoutName
																										: scout.scoutNameSuffix
																								}`}
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		)}
																	</div>
																);
															}
														)}
													</div>
												</ModalDialog>
											</>
										) : (
											<>
												{item.draftResult.map(
													(scout: any, i: number) => (
														<div
															key={i}
															className="w-full h-[47px] p-[5px] bg-[#d2d2d2] rounded flex flex-row justify-between items-center"
														>
															<div className="justify-start items-center gap-4 flex">
																<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																	<div className="justify-start items-center inline-flex">
																		<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																			{
																				scout.playerName
																			}
																		</div>
																	</div>
																	<div className="justify-start items-center gap-1 inline-flex">
																		<img
																			className="w-4 h-4"
																			src={
																				scout
																					.team
																					.image
																			}
																			alt={
																				scout
																					.team
																					.name
																			}
																		/>
																		<div className="text-black text-xs font-light font-bahnschrift leading-[14px]">
																			{
																				scout
																					.team
																					.name
																			}
																		</div>
																	</div>
																</div>
															</div>
															<div className="flex-col justify-center items-start gap-1.5 inline-flex">
																<div className="justify-start items-center inline-flex">
																	<div className="text-black text-base font-normal font-bahnschrift leading-[14px]">
																		{`${
																			scout.score
																		} ${
																			scout.score <=
																			1
																				? scout.scoutName
																				: scout.scoutNameSuffix
																		}`}
																	</div>
																</div>
															</div>
														</div>
													)
												)}
											</>
										)
									) : (
										<div className="w-20 h-7 p-2  rounded-lg justify-center items-center gap-2 inline-flex bg-[#ea8d27]">
											<div className="text-white text-xs font-normal font-bahnschrift leading-3">
												Pendente
											</div>
										</div>
									)
								) : (
									<div className="w-20 h-7 p-2  rounded-lg justify-center items-center gap-2 inline-flex bg-[#ea8d27]">
										<div className="text-white text-xs font-normal font-bahnschrift leading-3">
											Pendente
										</div>
									</div>
								)}
							</div>
						);
					case 2:
						return (
							<div className="flex flex-col justify-between gap-[5px]">
								{item.resultStatus === 1 ? (
									<div className="flex items-center gap-2 w-full">
										<div className="w-full py-1.5">
											<div className="relative">
												<Slider.Root
													className="relative flex items-center select-none touch-none w-full h-5"
													value={[item.numberResult]}
													max={parseInt(
														item.numberMax
													)}
													min={parseInt(
														item.numberMin
													)}
													step={1}
													disabled
												>
													<Slider.Track className="bg-[#cccccc] relative flex-grow rounded-full h-0.5">
														<Slider.Range className="absolute bg-primary rounded-full h-full" />
													</Slider.Track>
													<Slider.Thumb
														aria-label="Volume"
														className="block w-3.5 h-3.5 bg-primary rounded-lg focus:outline-none"
													>
														<div className="absolute min-w-2.5 h-2.5 text-center -top-[17px] ml-2 text-black text-[12px] font-normal font-bahnschrift">
															{item.numberResult}
														</div>
													</Slider.Thumb>
												</Slider.Root>
											</div>
										</div>
									</div>
								) : (
									<div className="w-20 h-7 p-2  rounded-lg justify-center items-center gap-2 inline-flex bg-[#ea8d27]">
										<div className="text-white text-xs font-normal font-bahnschrift leading-3">
											Pendente
										</div>
									</div>
								)}
							</div>
						);
					default:
						return null;
				}
			};

			return (
				<div
					ref={ref}
					tabIndex={-1}
					key={index}
					className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto"
				>
					<div className="bg-gray-100 rounded-lg min-h-[116px]">
						<div className="flex flex-col gap-0.5 px-4 py-1.5">
							<div className="self-stretch justify-end items-center gap-2.5 inline-flex ">
								<div className="text-primary text-xs font-semibold font-bahnschrift">
									{item.winPoints} pontos
								</div>
							</div>
							<div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
								<div className="self-stretch flex-col justify-start items-start gap-1 flex">
									<div className="self-stretch text-custom-black text-base font-semibold font-bahnschrift min-h-12">
										{item.title}
									</div>
								</div>
							</div>

							{renderOption(item.optionType)}
						</div>
					</div>
				</div>
			);
		}
	)
);

export default OptionItemView;
