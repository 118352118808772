import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTokenGetStreamStore, useTokenStore } from '../../../../store/token';
import {
	CurrencyFormatter,
	sentryCapture,
	showWalletAndBalance,
} from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useApi } from '../../../../hooks/useApi';
import { TournamentData } from '../../../../types/types';
import Tags from '../../../../components/ui/tags';
import CarouselTournament from '../../../../components/Carousel/Tournament';
import Button from '../../../../components/ui/button';
import { TableEnrolls } from '../../../../components/TableEnrolls';
import CheckboxCustomIcon from '../../../../components/ui/CheckBoxCustom';
import { RiDownload2Line, RiFileCopyLine } from '@remixicon/react';
import { ProvablyFairIcon } from '../../../../components/Icons/ProvablyFair';
import { copyToClipboard } from '../../../../helpers/utilsUi';
import { LiveChat } from '../../../../components/LiveChat';
import { FutProIcon } from '../../../../components/Icons/FutProIcon';

interface iProps {
	tabsSelect: string;
	filter: any;
	onChangeFilter: (type: string, status: string) => void;
}

export const MyTournament = ({
	tabsSelect,
	filter,
	onChangeFilter,
}: iProps) => {
	const api = useApi();
	const navigate = useNavigate();
	const token = useTokenStore((state: any) => state.token);
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);
	const pageSize = 3;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData[]>([]);
	const [openTournamentId, setOpenTournamentId] = useState<string | null>(
		null
	);
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		if (loading) return;

		if (tabsSelect === 'Meus Torneios') {
			fetchMyTournament(page);
		}
		if (tabsSelect === 'Meus Torneios' && data.length === 0) {
			fetchMyTournament(page);
		}
	}, [page, filter.open, filter.finish, tabsSelect]);

	useEffect(() => {
		if (loading) return;

		setHasMore(true);
		setData([]);
		setPage(0);
	}, [filter.open, filter.finish]);

	const fetchMyTournament = async (page: number) => {
		setLoading(true);
		try {
			if (totalPage !== 0 && page >= totalPage) {
				setHasMore(false);
				return;
			}
			const { open, finish } = filter;
			const res = await api.getMyTournament(
				{ pageSize, offset: page * pageSize, open, finish },
				token
			);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setTotalPage(res.totalPages);
			setData((prev: any) => [...prev, ...res.data]);
			if (res.totalPages <= 1) {
				setHasMore(false);
			} else {
				setHasMore(true);
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-col gap-6 mb-6">
			<div className="flex flex-row gap-4">
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.open === 'Inscrições aberta'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter('open', 'Inscrições aberta');
								} else {
									onChangeFilter('open', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter('open', 'Inscrições aberta');
								} else {
									onChangeFilter('open', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Inscrições aberta
						</div>
					</div>
				</div>
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.finish === 'Em Apuração'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Em Apuração');
								} else {
									onChangeFilter('closed', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Em Apuração');
								} else {
									onChangeFilter('closed', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Finalizados
						</div>
					</div>
				</div>
			</div>

			{data &&
				data.map((tournament, index) => (
					<div
						key={index}
						className="flex flex-col gap-2 bg-custom-white1 p-2.5 rounded-lg"
					>
						<div
							onClick={(e) => {
								e.stopPropagation();
								navigate(`/tournaments/${tournament.id}`);
							}}
							className="w-full flex flex-col gap-2 rounded-lg py-3"
						>
							<Tags status={tournament.tournamentStatus} />
							<div className="w-full h-60">
								{tournament?.content ? (
									tournament.content.image ? (
										<img
											className="lg:h-80 w-full h-full object-cover z-0"
											alt={tournament.content.title}
											src={tournament?.content.image}
										/>
									) : (
										<div
											className="w-full h-full z-0"
											dangerouslySetInnerHTML={{
												__html: tournament?.content
													.html,
											}}
										/>
									)
								) : (
									<div className="flex flex-col items-start justify-start gap-6 p-4 w-full h-full bg-[#d9d9d9] rounded-lg">
										<div className="flex flex-col items-start justify-start gap-0.5 w-full h-28">
											<div className="text-primary font-bold text-xs font-bahnschrift break-words w-full">
												<strong>Torneio</strong>
											</div>
											<div className="flex flex-col items-start justify-start gap-2 w-full h-full">
												<div className="flex flex-col items-start justify-start gap-1 w-full h-10">
													<div className="text-black font-bold text-2xl font-bahnschrift break-words w-full">
														<strong>
															{tournament?.title}
														</strong>
													</div>
													<div className="text-black text-base font-light font-bahnschrift break-words w-full flex-1 gap-1">
														Participe e concorra a{' '}
														{showWalletAndBalance() ? (
															'R$ '
														) : (
															<FutProIcon
																style="w-4 h-4"
																color="#000000"
															/>
														)}
														{CurrencyFormatter(
															Number(
																tournament.leaguesTournament.reduce(
																	(
																		acc,
																		league
																	) => {
																		return (
																			acc +
																			parseFloat(
																				league.prizeAmt
																			)
																		);
																	},
																	0
																)
															)
														)}{' '}
														em prêmio
													</div>
													<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
														Mostre que sabe tudo de
														futebol respondendo{' '}
														{
															tournament?.optionSelection
														}{' '}
														perguntas do quiz deste
														torneio.
													</div>
													<div className="text-black text-xs font-light font-bahnschrift leading-[1.2] break-words w-full">
														Entre as{' '}
														{
															tournament?.optionTotal
														}{' '}
														perguntas disponíveis,
														responda{' '}
														{
															tournament?.optionSelection
														}{' '}
														mostrando a sua
														habilidade para
														conquistar o máximo de
														pontos e garantir o seu
														prêmio.
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
								<div
									onClick={(e) => {
										e.stopPropagation();
										setOpenTournamentId((prev) =>
											prev === tournament.id
												? null
												: tournament.id
										);
									}}
									className="h-8 rounded-[68px] justify-center items-center float-right inline-flex relative bottom-9 right-3 cursor-pointer gap-1 font-bahnschrift text-xs text-[#a2a5ab]"
								>
									<ProvablyFairIcon />
									Provably Fair
								</div>
							</div>
						</div>
						{openTournamentId === tournament.id && (
							<div className="px-4 py-2 bg-gray-100 rounded-lg flex-col justify-center items-start gap-2.5 inline-flex">
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<ProvablyFairIcon />
									<div className="text-[#a2a5ab] text-[11px] font-semibold font-bahnschrift">
										Provably Fair
									</div>
								</div>

								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div className="w-full">
										<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
											Chave pública gerada pelo servidor:
											<br />
										</span>
										<div className="flex flex-row gap-1 items-center justify-center">
											<input
												type="text"
												className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
												value={tournament.pfPublicKey}
												disabled={true}
											/>
											<div
												onClick={() => {
													copyToClipboard(
														tournament.pfPublicKey
													);
												}}
											>
												<RiFileCopyLine className="text-neutral-800" />
											</div>
										</div>
									</div>
								</div>
								{tournament.pfPrivateKey && (
									<div className="self-stretch justify-start items-center gap-2 inline-flex">
										<div className="w-full">
											<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
												Chave privada gerada no
												servidor:
												<br />
											</span>
											<div className="flex flex-row gap-1 items-center justify-center">
												<input
													type="text"
													className="text-neutral-800 text-[13px] rounded font-semibold font-bahnschrift w-full p-1 border border-b-neutral-800"
													value={
														tournament.pfPrivateKey
													}
													disabled={true}
												/>
												<div
													onClick={() => {
														copyToClipboard(
															tournament.pfPrivateKey
														);
													}}
												>
													<RiFileCopyLine className="text-neutral-800" />
												</div>
											</div>
										</div>
									</div>
								)}
								{tournament?.pfFileUrl && (
									<div
										onClick={async () => {
											const link =
												document.createElement('a');
											link.href = tournament.pfFileUrl;
											link.setAttribute(
												'download',
												'provablyFair.txt'
											);
											link.click();
										}}
										className="w-full h-8 flex justify-end"
									>
										<RiDownload2Line className="w-6 h-6 text-black" />
									</div>
								)}
							</div>
						)}
						<CarouselTournament
							data={tournament?.leaguesTournament.sort(
								(a: any, b: any) => {
									return a.sequence - b.sequence;
								}
							)}
							tournamentId={tournament.id}
							tournamentStatus={tournament.tournamentStatus}
							enrollPage={false}
						/>
						<LiveChat
							id={index.toString()}
							tournamentId={tournament.id}
							token={tokenGetStream}
						/>
						<TableEnrolls
							thead={[
								{
									title:
										tournament.participants.filter(
											(enroll: any) => enroll.reqIsPlayer
										).length === 1
											? 'Minha Inscrição'
											: 'Minhas Inscrições',
								},
								{
									title: 'Liga',
								},
								{
									title: 'Posição',
								},
								{
									title: 'Pontos',
								},
								{
									title: 'Prêmio',
								},
							]}
							tbody={tournament.participants.filter(
								(enroll: any) => enroll.reqIsPlayer
							)}
						/>
					</div>
				))}

			{hasMore && (
				<Button
					isLoading={loading}
					onClick={() => {
						setPage(page + 1);
					}}
					className="w-full px-3.5 py-3.5"
				>
					Carregar mais
				</Button>
			)}
		</div>
	);
};
