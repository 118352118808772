import { RiCheckLine } from '@remixicon/react';

const CheckboxIcon = ({ checked, onCheckedChange }: any) => {
	return (
		<div
			className={
				'flex items-center gap-2 p-0.5 cursor-pointer border border-primary rounded-[5.33px] ' +
				(checked && 'bg-primary')
			}
		>
			<label>
				<input
					type="checkbox"
					checked={checked}
					onChange={onCheckedChange}
					className="hidden"
				/>
				<div className="flex items-center justify-center w-3.5 h-3.5">
					{checked && (
						<RiCheckLine size={14} className="text-white" />
					)}
				</div>
			</label>
		</div>
	);
};

export default CheckboxIcon;
