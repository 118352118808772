import { useState } from 'react';
import Button from '../../../../components/ui/button';
import { useApi } from '../../../../hooks/useApi';
import { useTokenStore } from '../../../../store/token';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { SentryError } from '../../../../types/enums';
import { sentryCapture } from '../../../../helpers/utils';
import { useAuthContext } from '../../../../contexts/Auth/AuthContext';
import { useBalanceStore } from '../../../../store/balance';
import Modal from '../../../../components/Modal/modal';
import { Warning } from '../../../../components/Icons/Warning';

export const AccountClosure = () => {
	const api = useApi();
	const { signOut } = useAuthContext();
	const token = useTokenStore((state: any) => state.token);
	const currentBalance = useBalanceStore((state) => state.balance);

	const [isLoading, setIsLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [typeClosure, setTypeClosure] = useState('');

	const handleCancelAccount = async (action: string) => {
		if (action === '') {
			return;
		}
		if (parseFloat(currentBalance) > 0) {
			toast(
				<Toast variant="error">
					Antes de {action === 'inactive' ? 'inativar' : 'encerrar'} a
					sua conta solicite o saque do seu saldo!
				</Toast>
			);
			return;
		}
		setIsLoading(true);
		try {
			const response = await api.cancelAccount(action, token);
			if (!response?.success) {
				throw new Error(response.message);
			}
			toast(
				<Toast variant="success">
					Conta {action === 'inactive' ? 'inativada' : 'encerrada'}{' '}
					com sucesso!
				</Toast>
			);
			signOut();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAccountClosure = (type: string) => {
		setModal(!modal);
		setTypeClosure(type);
	};

	return (
		<div className="flex flex-col gap-6 lg:w-[500px] lg:mx-auto">
			<p className="hidden lg:contents lg:text-4xl font-semibold text-left text-[#1d1f24] font-bahnschrift">
				Deseja encerrar a conta?
			</p>
			<p className="w-[342px] lg:w-[500px] text-base text-left text-custom-gray3 pb-52 lg:pb-2 font-bahnschrift">
				Tem certeza de que deseja encerrar sua conta? Confirmar essa
				ação resultará na perda permanente de seus dados.
			</p>

			<div className="flex flex-col gap-2 mb-3">
				<Button
					onClick={() => handleAccountClosure('inactive')}
					isLoading={isLoading}
					className="px-3.5 py-3 font-bahnschrift text-center lg:w-96"
					textCustom="text-sm text-custom-white"
					variant="cancel"
				>
					Inativar conta
				</Button>
				<Button
					onClick={() => handleAccountClosure('closed')}
					isLoading={isLoading}
					className="px-3.5 py-3 font-bahnschrift text-center lg:w-96"
					textCustom="text-sm text-custom-white"
					variant="cancel"
				>
					Encerrar conta
				</Button>
			</div>

			<Modal
				id="accountClosure"
				open={modal}
				onClose={() => setModal(!modal)}
			>
				<div className="flex flex-col gap-6 items-center">
					<Warning />

					<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
						Atenção
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						{typeClosure === 'inactive'
							? 'Deseja realmente inativar sua conta?'
							: 'Deseja realmente excluir sua conta?'}
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						{typeClosure === 'inactive'
							? 'Seus dados permanecerão guardados por mais 30 dias. Após esse período, não será mais possível recuperar a sua conta.'
							: 'Sua conta será excluída definitivamente, e não será possível recuperar os seus dados.'}
					</p>
					<div className="w-full pt-2 flex flex-row gap-2">
						<Button
							onClick={() => {
								setModal(!modal);
							}}
							variant="destructive"
							className={'w-full p-3'}
							isLoading={isLoading}
						>
							Não
						</Button>
						<Button
							onClick={() => {
								handleCancelAccount(typeClosure);
							}}
							isLoading={isLoading}
							className={'w-full p-3'}
						>
							Sim
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
