import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type Balancetore = {
	balance: string;
	saveBalance: (balance: string) => void;
	clearBalance: () => void;

	hideBalance: boolean;
	showAndHideBalance: (hideBalance: boolean) => void;
};

export const useBalanceStore = create(
	persist<Balancetore>(
		(set) => ({
			balance: '0',
			hideBalance: false,
			showAndHideBalance: (hideBalance) => set({ hideBalance }),
			saveBalance: (balance) => set({ balance }),
			clearBalance: () => set({ balance: '0' }),
		}),
		{
			name: 'balance',
			storage: createJSONStorage(() => localStorage),
		}
	)
);
