import { RiAddFill, RiRestartLine, RiSubtractLine } from '@remixicon/react';
import Footer from '../../../../components/Footer';
import { Layout } from '../../../../components/layout';
import { useUserProfileStore } from '../../../../store/user';
import { useEffect, useState } from 'react';
import { SendDocument } from '../../MyProfile/ProfileInfo/Edit/Document';
import { Pix } from '../../../../components/Icons/Pix';
import { CurrencyInput } from 'react-currency-mask';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { CurrencyFormatter, sentryCapture } from '../../../../helpers/utils';
import { FinanceStatusType, SentryError } from '../../../../types/enums';
import { socket, useApi } from '../../../../hooks/useApi';
import { useTokenStore } from '../../../../store/token';
import { RequestPixKey } from '../../../../components/Modal/RequestPixkey';
import { PixPending } from '../../../../components/Modal/PixPending';
import { WithdrawPixSuccess } from '../../../../components/Modal/WithdrawPixSuccess';
import { WithdrawPix } from '../../../../components/Modal/WithdrawPix';
import ButtonBack from '../../../../components/ui/buttonBack';
import { useBalanceStore } from '../../../../store/balance';

export const Withdraw = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state: any) => state.userData);
	const currentBalance = useBalanceStore((state) => state.balance);
	const [withdraw, setWithdraw] = useState<any>(0.0);
	const [isLoading, setIsLoading] = useState(false);
	const [openModalPixPending, setModalPixPending] = useState(false);
	const [openModalRequestPixkey, setModalRequestPixkey] = useState(false);
	const [openModalWithdrawPix, setModalWithdrawPix] = useState(false);
	const [openModalWithdrawPixSuccess, setModalWithdrawPixSuccess] =
		useState(false);
	const [withdrawPixPending, setWithdrawPixPending] = useState('');
	const [txid, setTxid] = useState<any>(null);
	const limitWithdraw: any = 5000.0;
	const pixValue = [1, 2, 5, 10, 20, 30, 50, 100, 200];
	const paymentMethods = [
		{
			method: 'pix',
			title: 'Saque usando Pix',
			icon: () => <Pix />,
		},
	];
	useEffect(() => {
		socket.on('connect', () => {});
		socket.on('disconnect', () => {});
		if (txid) {
			socket.connect();
			socket.on('withdrawConfirmation', (data) => {
				const { transactionId, status } = data;
				if (
					txid === transactionId &&
					status === FinanceStatusType.EXECUTED
				) {
					setModalWithdrawPix(false);
					setModalWithdrawPixSuccess(!openModalWithdrawPixSuccess);
					socket.disconnect();
				}
			});
		}
	}, [txid]);

	const subtractWithdraw = () => {
		if (withdraw <= 0) {
			return;
		}
		setWithdraw(withdraw - 1);
	};
	const addWithdraw = () => {
		if (Number(currentBalance) <= withdraw) return;
		setWithdraw(withdraw + 1);
	};
	const handleWithdraw = async () => {
		if (!userData?.pixKey) {
			setModalRequestPixkey(!openModalRequestPixkey);
			return;
		}
		if (withdraw <= 0) {
			toast(
				<Toast variant="error">
					Selecione ou informe valor de saque.
				</Toast>
			);
			return;
		}
		const body = {
			provisionAmt: withdraw,
		};
		setIsLoading(true);
		try {
			const response = await api.createNewWithdraw(body, token);
			if (response?.pendingPix) {
				setWithdrawPixPending(response.withdrawPixPending);
				setModalPixPending(!openModalRequestPixkey);
				return;
			}
			if (!response?.success) {
				throw new Error(response.message);
			}
			if (response?.txid) {
				setTxid(response.txid);
			}
			setModalWithdrawPix(!openModalWithdrawPix);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'handleDeposit', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};
	const modalRequestPixkey = () => {
		return (
			<RequestPixKey
				open={openModalRequestPixkey}
				onClose={() => {
					setModalRequestPixkey(!openModalRequestPixkey);
					setWithdraw(0.0);
				}}
			/>
		);
	};
	const modalPixPending = () => {
		return (
			<PixPending
				open={openModalPixPending}
				valuePending={withdrawPixPending}
				onClose={() => {
					setModalPixPending(!openModalPixPending);
					setWithdraw(0.0);
				}}
			/>
		);
	};
	const modalWithdrawPix = () => {
		return (
			<WithdrawPix
				open={openModalWithdrawPix}
				value={withdraw}
				onClose={() => {
					setModalWithdrawPix(!openModalWithdrawPix);
					setWithdraw(0.0);
				}}
			/>
		);
	};
	const modalWithdrawPixSuccess = () => {
		return (
			<WithdrawPixSuccess
				open={openModalWithdrawPixSuccess}
				value={withdraw}
				onClose={() => {
					setModalWithdrawPixSuccess(!openModalWithdrawPixSuccess);
					setWithdraw(0.0);
					setTxid(null);
				}}
			/>
		);
	};

	return (
		<Layout>
			<div className="flex flex-col py-6 px-3.5 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
				<div className="flex items-center flex-row justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Saque
					</p>
					<div></div>
				</div>

				{!userData.documentNumber && (
					<SendDocument isDocumentFalse={userData?.documentNumber} />
				)}

				<div className="flex flex-col gap-3 px-5 pt-6">
					<div className="">
						<p className="text-base text-left text-custom-gray1 pb-1.5 font-bahnschrift">
							Informe o valor a sacar
						</p>
						<p className="text-sm text-left text-custom-gray3 font-bahnschrift">
							Novos saques estão limitados a R$ 5.000,00
						</p>
					</div>

					<div className="grid grid-cols-3 gap-3 items-center justify-center">
						{pixValue.map((value, index) => {
							const isDisable =
								isLoading || Number(currentBalance) < value;
							return (
								<button
									disabled={isDisable}
									onClick={() => setWithdraw(value)}
									className={
										'flex justify-center items-center  gap-2.5 px-[29px] py-3 rounded-lg border border-primary ' +
										(value === withdraw &&
											'bg-custom-gray') +
										(isDisable && ' bg-custom-gray6')
									}
									key={index}
								>
									<p className="flex-grow-0 flex-shrink-0 text-sm font-bahnschrift font-semibold text-center text-primary">
										R$ {CurrencyFormatter(Number(value))}
									</p>
								</button>
							);
						})}
					</div>

					<div className="py-3">
						<div className="flex flex-row items-center justify-between rounded-lg border border-r-custom-gray2 py-4 px-1.5 ">
							<button
								disabled={isLoading}
								onClick={subtractWithdraw}
								className="cursor-pointer h-7 w-7 rounded bg-primary items-center justify-center flex mx-2"
							>
								<RiSubtractLine
									size={16}
									className="text-custom-white"
								/>
							</button>

							<div className="relative w-3/4">
								<label className="absolute -top-1.5 left-2.5 text-[10px] text-center text-custom-gray3 focus:border-0 active:border-0 font-bahnschrift">
									R$
								</label>
								<CurrencyInput
									value={withdraw}
									onChangeValue={(_, originalValue) => {
										if (limitWithdraw < originalValue) {
											toast(
												<Toast variant="error">
													Seu limite de saque é R${' '}
													{CurrencyFormatter(
														Number(limitWithdraw)
													)}
												</Toast>
											);
										}
										if (currentBalance < originalValue) {
											return setWithdraw(currentBalance);
										}
										setWithdraw(originalValue);
									}}
									hideSymbol
									InputElement={
										<input
											disabled={
												isLoading ||
												currentBalance == withdraw
											}
											className="w-full text-xl font-bold text-center font-bahnschrift text-custom-gray2 focus:border-0 active:border-0 focus-visible:border-0 outline-none"
										/>
									}
								/>
							</div>

							<button
								disabled={isLoading}
								onClick={addWithdraw}
								className="cursor-pointer h-7 w-7 rounded bg-primary items-center justify-center flex mx-2"
							>
								<RiAddFill
									size={16}
									className="text-custom-white"
								/>
							</button>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<p className="text-base text-left text-custom-gray1 pb-1.5 font-bahnschrift">
							Escolha a forma de pagamento
						</p>

						<div className="flex flex-col gap-4">
							{isLoading ? (
								<div className="flex items-center justify-center">
									<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
								</div>
							) : (
								paymentMethods.map((methods: any, index) => (
									<button
										onClick={() => handleWithdraw()}
										disabled={isLoading}
										className="flex flex-row justify-between rounded-lg bg-custom-gray7 border border-custom-gray4 px-6 py-3"
										key={index}
									>
										<p className="text-sm text-left text-custom-gray1 font-bahnschrift">
											{methods?.title}
										</p>
										{methods?.icon && methods.icon()}
									</button>
								))
							)}
						</div>
					</div>
				</div>

				{modalRequestPixkey()}
				{modalPixPending()}
				{modalWithdrawPix()}
				{modalWithdrawPixSuccess()}
			</div>

			<Footer />
		</Layout>
	);
};
