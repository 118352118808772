import { useEffect, useRef, useState } from 'react';

import { RiCheckLine, RiPencilFill } from '@remixicon/react';
import { useUserProfileStore } from '../../../../../store/user';
import TextInput from '../../../../../components/ui/TextInput';
import Button from '../../../../../components/ui/button';
import Modal from '../../../../../components/Modal/modal';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { UpdatePixKey } from '../../../../../types/userData/validation';
import { helpButton } from '../../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';
import { useTokenStore } from '../../../../../store/token';

export const EditPixKey = () => {
	const api = useApi();
	const { updateUserData } = useUserProfileStore();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state: any) => state.userData);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pixKeyType, setPixKeyType] = useState<any>(null);
	const [activeStep, setActiveStep] = useState(1);
	const pix0 = useRef<HTMLInputElement>(null);
	const pix1 = useRef<HTMLInputElement>(null);
	const pix2 = useRef<HTMLInputElement>(null);
	const pix3 = useRef<HTMLInputElement>(null);

	const steps = [
		{ step: 1, title: 'Selecione o tipo de chave' },
		{ step: 2, title: 'Informe a chave' },
	];

	const pixType = [
		{
			type: 0,
			name: 'CPF',
		},
		{
			type: 1,
			name: 'E-mail',
		},
		{
			type: 2,
			name: 'Celular',
		},
		{
			type: 3,
			name: 'Aleatória',
		},
	];

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<z.infer<typeof UpdatePixKey>>({
		resolver: zodResolver(UpdatePixKey),
		mode: 'onChange',
	});

	useEffect(() => {
		if (activeStep === 2 && pix0.current) {
			pix0.current.focus();
		}
		if (activeStep === 2 && pix1.current) {
			pix1.current.focus();
		}
		if (activeStep === 2 && pix2.current) {
			pix2.current.focus();
		}
		if (activeStep === 2 && pix3.current) {
			pix3.current.focus();
		}
	}, [activeStep]);

	useEffect(() => {
		if (!openModal) {
			setActiveStep(1);
			reset({ pixKeyDocumentNumber: '' });
			reset({ pixKeyEmail: '' });
			reset({ pixKeyPhone: '' });
			reset({ pixKeyRandom: '' });
		}
	}, [openModal]);

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<div className="flex gap-2 flex-wrap">
						{pixType.map((item, index) => (
							<Button
								key={index}
								onClick={() => {
									setPixKeyType(item.type);
									setActiveStep(step + 1);
								}}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								{item.name}
							</Button>
						))}
					</div>
				);
			case 2:
				return formSelect(pixKeyType, step);
			default:
				return null;
		}
	};

	const formSelect = (pixKeyType: number, step: number) => {
		switch (pixKeyType) {
			case 0:
				return (
					<Controller
						control={control}
						name="pixKeyDocumentNumber"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									mask="999.999.999-99"
									type="text"
									placeholder="Digite a chave"
									value={value || ''}
									error={
										errors?.pixKeyDocumentNumber?.message
									}
									onChange={onChange}
									inputMode="tel"
									ref={pix0}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => {
											reset({ pixKeyDocumentNumber: '' });
											setActiveStep(step - 1);
										}}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value ||
											!!errors?.pixKeyDocumentNumber
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 1:
				return (
					<Controller
						control={control}
						name="pixKeyEmail"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									placeholder="Digite a chave"
									value={value}
									error={errors?.pixKeyEmail?.message}
									onChange={onChange}
									ref={pix1}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => {
											reset({ pixKeyEmail: '' });
											setActiveStep(step - 1);
										}}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value || !!errors?.pixKeyEmail
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 2:
				return (
					<Controller
						control={control}
						name="pixKeyPhone"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									mask="(99) 9 9999-9999"
									type="text"
									inputMode="tel"
									placeholder="Digite a chave"
									value={value || ''}
									error={errors?.pixKeyPhone?.message}
									onChange={onChange}
									ref={pix2}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => {
											reset({ pixKeyPhone: '' });
											setActiveStep(step - 1);
										}}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value || !!errors?.pixKeyPhone
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 3:
				return (
					<Controller
						control={control}
						name="pixKeyRandom"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									placeholder="Digite a chave"
									value={value || ''}
									error={errors?.pixKeyRandom?.message}
									onChange={onChange}
									ref={pix3}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => {
											reset({ pixKeyRandom: '' });
											setActiveStep(step - 1);
										}}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										type="submit"
										disabled={
											!value || !!errors?.pixKeyRandom
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Confirmar
									</Button>
								</div>
							</div>
						)}
					/>
				);
			default:
				return null;
		}
	};

	const handleUpdate = async (data: z.infer<typeof UpdatePixKey>) => {
		const pixValue = (type: number) => {
			switch (type) {
				case 0:
					return data.pixKeyDocumentNumber;
				case 1:
					return data.pixKeyEmail;
				case 2:
					return data.pixKeyPhone;
				case 3:
					return data.pixKeyRandom;
				default:
					return data.pixKeyDocumentNumber;
			}
		};
		const pixKeyData =
			pixKeyType !== null && pixKeyType !== undefined
				? { type: pixKeyType, value: pixValue(pixKeyType) }
				: null;
		setIsLoading(true);

		try {
			const response = await api.updatePixKey(
				{ pixKey: pixKeyData },
				token
			);
			if (!response?.success) {
				throw new Error(response.message);
			}

			setActiveStep(1);
			updateUserData('pixKey', pixKeyData);
			reset({ pixKeyDocumentNumber: '' });
			reset({ pixKeyEmail: '' });
			reset({ pixKeyPhone: '' });
			reset({ pixKeyRandom: '' });
			setOpenModal(!openModal);
			toast(
				<Toast variant="success">
					Chave pix atualizada com sucesso
				</Toast>
			);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'editPixKey', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="flex items-center gap-4">
			<div className="flex-1">
				<TextInput
					type="text"
					placeholder="Chave Pix"
					value={userData?.pixKey?.value || ''}
					onChange={() => {}}
					disabled
				/>
			</div>

			<Button
				onClick={() => setOpenModal(!openModal)}
				variant="help"
				className={'w-12 h-12'}
			>
				<RiPencilFill size={24} color="#3A3D44" className={''} />
			</Button>

			<Modal
				id="email"
				open={openModal}
				onClose={() => setOpenModal(!openModal)}
			>
				<div className="">
					<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
						Atualizar chave pix
					</p>

					<form
						onSubmit={handleSubmit(handleUpdate)}
						className="mb-7 flex flex-col"
					>
						<ol className="relative mx-4">
							{steps.map((item, index) => (
								<li
									key={index}
									className="relative flex flex-col"
								>
									<div className="flex items-start relative">
										<div className="flex flex-col items-center -start-4">
											<span
												className={`absolute z-10 flex items-center justify-center w-7 h-7 font-bahnschrift rounded-full ring-custom-white ${
													item.step <= activeStep
														? 'bg-primary text-custom-white'
														: 'bg-custom-gray5 text-custom-gray2'
												}`}
											>
												{item.step < activeStep ? (
													<RiCheckLine
														size={20}
														color="#ffffff"
														className=""
													/>
												) : (
													item.step
												)}
											</span>
											<div
												className={`text-gray-500 flex border-s w-[1px] absolute ${
													item.step < activeStep
														? 'border-primary h-full -z-10'
														: activeStep ===
																item.step &&
														  activeStep <
																steps.length
														? 'border-primary h-full'
														: item.step <=
																steps.length -
																	1 &&
														  'border-custom-gray5 h-10'
												}`}
											></div>
										</div>

										<div className="flex-1 ml-8 mb-4">
											<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
												{item.title}
											</h3>
											<div
												className={`${
													item.step !== activeStep
														? 'hidden'
														: ''
												}`}
											>
												{stepForm(item.step)}
											</div>
										</div>
									</div>
								</li>
							))}
						</ol>
					</form>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
