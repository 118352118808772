import { useState } from 'react';

import { RiCheckLine, RiPencilFill } from '@remixicon/react';
import { useUserProfileStore } from '../../../../../store/user';
import TextInput from '../../../../../components/ui/TextInput';
import Button from '../../../../../components/ui/button';
import Modal from '../../../../../components/Modal/modal';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { UpdateNick } from '../../../../../types/userData/validation';
import { helpButton } from '../../../../../components/ui/helpButton';
import { sentryCapture } from '../../../../../helpers/utils';
import { SentryError } from '../../../../../types/enums';
import { useApi } from '../../../../../hooks/useApi';
import { useTokenStore } from '../../../../../store/token';
import { toast } from 'sonner';
import Toast from '../../../../../components/ui/toast';

export const EditNick = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state: any) => state.userData);
	const { updateUserData } = useUserProfileStore();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [nickSuggestion, setNickSuggestion] = useState([]);
	const [activeStep, setActiveStep] = useState(1);

	const steps = [{ step: 1, title: 'Informe o seu apelido' }];

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
		setValue,
		watch,
	} = useForm<z.infer<typeof UpdateNick>>({
		resolver: zodResolver(UpdateNick),
		mode: 'onChange',
		defaultValues: {
			nick: '',
		},
	});

	const nickValue: any = watch('nick');

	const handleUpdate = async (data: z.infer<typeof UpdateNick>) => {
		setIsLoading(true);
		try {
			const findNick = await api.validateNewNick(data.nick);
			if (!findNick?.success) {
				setNickSuggestion(findNick.suggestions);
				throw new Error(findNick.message);
			}

			const response = await api.updateNick(data, token);
			if (!response?.success) {
				throw new Error(response.message);
			}

			updateUserData('nick', data.nick);
			reset({ nick: '' });
			setNickSuggestion([]);
			setOpenModal(!openModal);
			setActiveStep(1);
			toast(
				<Toast variant="success">Apelido alterado com sucesso!</Toast>
			);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<Controller
						control={control}
						name="nick"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									mask="@***************"
									placeholder="Apelido"
									value={value}
									error={errors?.nick?.message}
									onChange={onChange}
								/>

								{nickSuggestion.length > 0 && (
									<div>
										<p className="text-xs py-1 text-left text-custom-gray3 font-bahnschrift">
											Apelido não disponivel... aceita
											sugestões?
										</p>

										<div className="grid grid-cols-4 gap-1">
											{nickSuggestion.map(
												(
													nick: string,
													index: number
												) => (
													<p
														key={index}
														onClick={() => {
															setValue(
																'nick',
																nick
															);
														}}
														className="text-xs cursor-pointer font-semibold text-left text-primary"
													>
														{nick}
													</p>
												)
											)}
										</div>
									</div>
								)}
							</div>
						)}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex items-center gap-4">
			<div className="flex-1">
				<TextInput
					type="text"
					placeholder="Apelido"
					value={userData?.nick ?? ''}
					onChange={() => {}}
					disabled
				/>
			</div>

			<Button
				onClick={() => setOpenModal(!openModal)}
				variant="help"
				className={'w-12 h-12'}
			>
				<RiPencilFill size={24} color="#3A3D44" className={''} />
			</Button>

			<Modal
				id="login"
				open={openModal}
				onClose={() => setOpenModal(!openModal)}
			>
				<div className="">
					<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
						Atualizar apelido
					</p>

					<form
						onSubmit={handleSubmit(handleUpdate)}
						className="mb-7 flex flex-col"
					>
						<ol className="relative mx-4">
							{steps.map((item, index) => (
								<li
									key={index}
									className="relative flex flex-col"
								>
									<div className="flex items-start relative">
										<div className="flex flex-col items-center -start-4">
											<span
												className={`absolute z-10 flex items-center justify-center w-7 h-7 rounded-full ring-custom-white font-bahnschrift ${
													item.step <= activeStep
														? 'bg-primary text-custom-white'
														: 'bg-custom-gray5 text-custom-gray2'
												}`}
											>
												{item.step < activeStep ? (
													<RiCheckLine
														size={20}
														color="#ffffff"
														className=""
													/>
												) : (
													item.step
												)}
											</span>
										</div>

										<div className="flex-1 ml-8 mb-4">
											<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
												{item.title}
											</h3>
											<div
												className={`${
													item.step !== activeStep
														? 'hidden'
														: ''
												}`}
											>
												{stepForm(item.step)}
											</div>
										</div>
									</div>
								</li>
							))}
						</ol>

						<Button
							type="submit"
							disabled={!nickValue || !!errors?.nick}
							isLoading={isLoading}
							className="px-5 py-3 font-bahnschrift text-center float-left"
							textCustom="text-sm"
						>
							Confirmar
						</Button>
					</form>

					<div className="flex flex-row justify-between items-center">
						{helpButton()}
					</div>
				</div>
			</Modal>
		</div>
	);
};
