import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import { useEffect, useState } from 'react';
import { sentryCapture } from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { Scoreboard, ScoreboardData } from '../../../types/types';
import { RiRestartLine } from '@remixicon/react';
import ButtonBack from '../../../components/ui/buttonBack';
import { TableScoreboardFeed } from '../../../components/TableScoreboardFeed';
import TabsButtonScoreBoard from '../../../components/TabsButtonScoreBoard';
import { useStream } from '../../../contexts/GetStream/StreamContext';
import Button from '../../../components/ui/button';

export const ScoreboardById = () => {
	const { id } = useParams();
	const api = useApi();
	const { clientGetStream } = useStream();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<ScoreboardData | null>(null);
	const [scoreboard, setScoreboard] = useState<Scoreboard[]>([]);

	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const limit = page === 0 ? 20 : 10;
	const offset = page === 0 ? 0 : 20 + (page - 1) * 10;

	useEffect(() => {
		fetchScoreboard();
	}, []);

	useEffect(() => {
		if (data) {
			findTokenScore();
		}
	}, [data]);

	const fetchScoreboard = async () => {
		if (!id) return;
		try {
			setLoading(true);

			const res = await api.getScoreboard(id, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setData(res.data);
			setPage((prevPage) => prevPage + 1);
			setScoreboard((prevFeed: any) => [
				...prevFeed,
				...res.data.scoreboard,
			]);

			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const findTokenScore = async () => {
		const response = await api.getTokenFeed();
		if (!response?.success) {
			throw new Error(response.message);
		}
		const { token } = response;
		if (clientGetStream) {
			const scoreUpdate = clientGetStream.feed(
				'scoreboard',
				'system',
				token
			);
			scoreUpdate.subscribe(handleScoreUpdate);
		}
	};
	const handleScoreUpdate = async (dataGetStream: any) => {
		const update = JSON.parse(dataGetStream.new[0].object).update;
		const tournamentId = JSON.parse(
			dataGetStream.new[0].object
		).tournamentId;

		if (update && tournamentId === data?.content.tournament?.id) {
			console.log('Atualizou o scoreboard!');
			updateScoreboard();
		}
	};

	const updateScoreboard = async () => {
		if (!id) return;
		try {
			const res = await api.getScoreboard(id, 20, 0);
			if (!res?.success) {
				throw new Error(res.message);
			}
			setScoreboard(res.data.scoreboard);
			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				setPage(res.currentPage);
			} else {
				setHasMore(true);
				setPage(1);
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		}
	};

	const handleLoadMore = async () => {
		if (!id) return;
		try {
			setIsLoading(true);

			const res = await api.getScoreboard(id, limit, offset);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setScoreboard((prevFeed: any) => [
				...prevFeed,
				...res.data.scoreboard,
			]);
			setPage((prevPage) => prevPage + 1);
			if (res.currentPage >= res.totalPages) {
				setHasMore(false);
				return;
			}
		} catch (error: any) {
			sentryCapture(error, 'fetchScoreboard', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout>
			<div className="px-3 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Classificação
					</p>
					<div></div>
				</div>
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{data && scoreboard && (
							<>
								<div className="w-full rounded-lg gap-2 flex flex-col items-start">
									<div className="relative w-full h-48 lg:h-48 overflow-hidden rounded-xl bg-custom-gray10">
										{data.content.image ? (
											<img
												className="lg:h-48 w-full h-full object-cover z-0"
												alt={data.content.title}
												src={data.content.image}
											/>
										) : (
											<div
												className={`absolute inset-0 w-full object-cover px-4 z-0 rounded-b-xl`}
												dangerouslySetInnerHTML={{
													__html: data.content.html,
												}}
											/>
										)}
									</div>
								</div>

								<TabsButtonScoreBoard
									initialPage={
										data.leaguesTournament[0].league.title
									}
									render={data.leaguesTournament?.map(
										(league: any, index: number) => ({
											title: league.league.title,
											renderPage: () => (
												<TableScoreboardFeed
													thead={[
														{
															title: 'Posição',
														},
														{
															title: 'Pontos',
														},
														{
															title: 'Nick',
														},
														{
															title: '',
														},
													]}
													tbody={scoreboard
														.filter(
															(
																leagueScore: any
															) =>
																leagueScore.league ===
																league.league
																	.title
														)
														.sort(
															(a, b) =>
																Number(
																	a.position
																) -
																Number(
																	b.position
																)
														)}
													tournamentStatus={
														data?.content.tournament
															?.tournamentStatus
													}
												/>
											),
										})
									)}
								/>
								<div
									className={
										'flex flex-row gap-2.5 ' +
										(!hasMore && 'mb-6')
									}
								>
									<div className="w-3.5 h-3.5 bg-green-200 rounded-3xl border border-green-700" />
									<div className="text-black text-xs font-medium font-bahnschrift leading-none">
										{data.content.tournament
											?.tournamentStatus === 3
											? 'Garantiu o prêmio da liga.'
											: 'Na frente na briga pelo prêmio'}
									</div>
								</div>

								{hasMore && (
									<Button
										isLoading={isLoading}
										onClick={handleLoadMore}
										className="w-full px-3.5 py-3.5 mb-6"
									>
										Carregar mais
									</Button>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</Layout>
	);
};
