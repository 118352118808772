import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type NotificationStore = {
	notification: number;
	saveNotification: (count: number) => void;
	clearNotification: () => void;
};

export const useNotificationStore = create(
	persist<NotificationStore>(
		(set) => ({
			notification: 0,
			saveNotification: (count) =>
				set((state) => ({ notification: state.notification + count })),
			clearNotification: () => set({ notification: 0 }),
		}),
		{
			name: 'notification',
			storage: createJSONStorage(() => localStorage),
		}
	)
);
