export const ProvablyFairIcon = () => {
	return (
		<svg
			width="23"
			height="26"
			viewBox="0 0 23 26"
			xmlns="http://www.w3.org/2000/svg"
			className="w-4 h-4"
		>
			<defs>
				<linearGradient
					x1="9.9656%"
					y1="0%"
					x2="97.7428%"
					y2="0%"
					id="a"
				>
					<stop stopColor="#57B314" offset="0%" />
					<stop stopColor="#1E9F04" offset="100%" />
				</linearGradient>
			</defs>
			<g
				transform="translate(-194 -17)"
				fill="url(#a)"
				fillRule="nonzero"
			>
				<path d="M215.7627 21.4809c-3.5703-.9783-7.1616-2.5016-10.3856-4.405a.5456.5456 0 0 0-.555 0c-3.317 1.9581-6.7143 3.399-10.386 4.405a.5458.5458 0 0 0-.4016.5265v5.5773c0 5.7435 2.6496 9.5453 4.8725 11.7231 2.393 2.3449 5.1805 3.6416 6.1926 3.6416 1.012 0 3.7995-1.2967 6.1925-3.6416 2.2227-2.1778 4.8722-5.9795 4.8722-11.723v-5.5775a.5458.5458 0 0 0-.4016-.5264Zm-.69 6.1038c0 5.3643-2.4714 8.9119-4.5447 10.9433-2.3532 2.3058-4.8693 3.3296-5.4284 3.3296-.5592 0-3.0754-1.0238-5.4287-3.3296-2.0732-2.0315-4.5446-5.579-4.5446-10.9433v-5.163c3.5111-.997 6.781-2.3882 9.9734-4.2433 3.1126 1.8059 6.5456 3.2663 9.973 4.243v5.1633Z" />
				<path d="M200.2612 22.3606a.5458.5458 0 0 0-.708-.3078 44.4103 44.4103 0 0 1-2.9748 1.0512.546.546 0 0 0-.3825.5208v2.108a.5459.5459 0 0 0 1.0917 0v-1.709a45.5032 45.5032 0 0 0 2.6658-.9553.5457.5457 0 0 0 .3078-.7079ZM201.298 22.4642a.546.546 0 0 0 .219-.046l.01-.0043a.5447.5447 0 0 0 .2795-.7185c-.121-.2762-.4444-.4011-.7203-.2802l-.0088.0038a.5446.5446 0 0 0-.2804.7181.5463.5463 0 0 0 .501.327ZM211.603 34.601a.546.546 0 0 0-.7555.1585c-.4498.6884-.9775 1.3477-1.5687 1.9593a13.9425 13.9425 0 0 1-1.5657 1.393.546.546 0 0 0 .662.8681 15.0457 15.0457 0 0 0 1.6885-1.5023c.6392-.661 1.2104-1.3747 1.6978-2.121a.5458.5458 0 0 0-.1583-.7555ZM206.4454 38.9679l-.0313.0184a.5459.5459 0 0 0 .5469.9447l.0361-.0212a.5458.5458 0 1 0-.5517-.942ZM201.3788 28.8606a1.763 1.763 0 0 0-1.255-.5197 1.764 1.764 0 0 0-1.2552.5197c-.692.6921-.692 1.8183 0 2.5104l3.2232 3.223c.3352.3352.781.5197 1.255.5197.4742 0 .92-.1845 1.2552-.5198l6.7286-6.7286c.692-.6923.692-1.8183 0-2.5102a1.7633 1.7633 0 0 0-1.2552-.5197c-.4741 0-.9199.1846-1.2551.5197l-5.4735 5.4735-1.968-1.968Zm8.2134-2.7335c.129-.129.3007-.2.4833-.2a.679.679 0 0 1 .4832.2c.2663.2664.2663.6999-.0001.9663l-6.7285 6.7286a.6789.6789 0 0 1-.4832.2.679.679 0 0 1-.4833-.2l-3.223-3.223c-.2665-.2664-.2665-.7-.0002-.9663.129-.129.3007-.2.4833-.2.1826 0 .3542.071.4832.1999l2.354 2.354a.5461.5461 0 0 0 .7718 0l5.8595-5.8595Z" />
			</g>
		</svg>
	);
};
