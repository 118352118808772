import OptionItemView from './OptionItemView';

export const ResultsOptions = ({ data }: any) => {
	return (
		<div className="flex flex-col gap-1.5">
			{data.optionsTournament.map((options: any, index: number) => (
				<OptionItemView
					key={index}
					item={options}
					status={data.tournamentStatus}
					index={options.id}
				/>
			))}
		</div>
	);
};
