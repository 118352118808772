import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Slide, SlideshowRef } from 'react-slideshow-image';
import { FeedData } from '../../types/types';
import FeedItem from '../FeedItem';
import 'react-slideshow-image/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';

const FeedBanner = ({ data, isLoading }: any) => {
	const slideRef = useRef<SlideshowRef>(null);
	const timerRef = useRef<NodeJS.Timeout | null>(null);
	const [currentIndex, setCurrentIndex] = useState(0);
	const durationSlide = 5000;

	const resetTimer = () => {
		if (timerRef.current) {
			clearInterval(timerRef.current);
		}

		timerRef.current = setInterval(() => {
			const newIndex = (currentIndex + 1) % data.length;
			setCurrentIndex(newIndex);
			slideRef.current?.goTo(newIndex);
		}, durationSlide);
	};

	useEffect(() => {
		resetTimer();

		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, [currentIndex, data.length]);

	const indicators = (index: any) => {
		return (
			<div
				aria-label={`Go to slide ${index + 1}`}
				className={`${
					currentIndex == index
						? 'w-5 bg-primary'
						: 'w-2 bg-custom-black'
				} h-2 ml-1 rounded-full`}
			></div>
		);
	};

	return (
		<>
			{isLoading ? (
				<div className="px-3">
					<Skeleton className="w-full flex-1 lg:max-w-screen-xl mx-auto h-[237px] rounded-[8px] " />
				</div>
			) : (
				<Slide
					autoplay={false}
					arrows={false}
					duration={durationSlide}
					indicators={indicators}
					ref={slideRef}
					infinite={true}
				>
					{data &&
						data
							.sort(
								(a: any, b: any) =>
									parseInt(a.position) - parseInt(b.position)
							)
							.map((item: FeedData, index: number) => (
								<FeedItem
									item={item}
									index={index}
									key={index}
								/>
							))}
				</Slide>
			)}
		</>
	);
};

export default FeedBanner;
