import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { useAuthContext } from '../contexts/Auth/AuthContext';

interface ProtectedRouteProps {
	element: ReactElement;
}

export function ProtectedRoute({ element }: ProtectedRouteProps): ReactElement {
	const { user } = useAuthContext();

	if (!user) {
		return <Navigate to="/" replace />;
	}

	return element;
}
