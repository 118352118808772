import { useRef, useEffect, memo } from 'react';
import { LeaguesTournament } from '../../../types/types';
import { RiCheckLine } from '@remixicon/react';
import { TournamentTypeDisplay } from '../../../types/enums';
import {
	CurrencyFormatter,
	showWalletAndBalance,
} from '../../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { FutProIcon } from '../../Icons/FutProIcon';

interface TabsProps {
	data: LeaguesTournament[] | undefined;
	tournamentId: string | undefined;
	tournamentStatus: number | undefined;
	enrollPage: boolean;
}

const CarouselTournament = memo(
	({ data, tournamentStatus, tournamentId, enrollPage }: TabsProps) => {
		const tabListRef = useRef<HTMLUListElement>(null);
		const activeTabRef = useRef<HTMLLIElement>(null);
		const navigate = useNavigate();

		useEffect(() => {
			if (activeTabRef.current && tabListRef.current) {
				const activeTab = activeTabRef.current;
				const tabList = tabListRef.current;
				const activeTabRect = activeTab.getBoundingClientRect();
				const tabListRect = tabList.getBoundingClientRect();

				if (activeTabRect.left < tabListRect.left) {
					tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
				} else if (activeTabRect.right > tabListRect.right) {
					tabList.scrollLeft +=
						activeTabRect.right - tabListRect.right;
				}
			}
		}, [data]);

		let isDragging = false;
		let startX: number;
		let scrollLeft: number;

		const handleMouseDown = (e: React.MouseEvent) => {
			isDragging = true;
			startX = e.pageX - (tabListRef.current?.offsetLeft || 0);
			scrollLeft = tabListRef.current?.scrollLeft || 0;
			document.body.style.userSelect = 'none';
		};
		const handleMouseLeave = () => {
			isDragging = false;
			document.body.style.userSelect = '';
		};
		const handleMouseUp = () => {
			isDragging = false;
			document.body.style.userSelect = '';
		};
		const handleMouseMove = (e: React.MouseEvent) => {
			if (!isDragging) return;
			e.preventDefault();
			const x = e.pageX - (tabListRef.current?.offsetLeft || 0);
			const walk = (x - startX) * 2;
			if (tabListRef.current) {
				tabListRef.current.scrollLeft = scrollLeft - walk;
			}
		};

		return (
			<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto">
				<ul
					className="flex gap-1 mb-0 list-none flex-row overflow-x-auto whitespace-nowrap scrollbar-hide"
					role="tablist2"
					ref={tabListRef}
					onMouseDown={handleMouseDown}
					onMouseLeave={handleMouseLeave}
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}
				>
					{data?.map((tabsTitle, index) => (
						<li
							key={index}
							className={
								'flex flex-shrink-0 select-none font-bahnschrift' +
								(!enrollPage && ' cursor-pointer')
							}
							ref={index === 0 ? activeTabRef : null}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();

								if (!enrollPage) {
									navigate(`/tournaments/${tournamentId}`);
								}
							}}
						>
							<a
								data-toggle="tab"
								role="tablist2"
								aria-selected={index === 0}
							>
								{tabsTitle.content && (
									<div
										className={
											'w-full h-auto rounded-lg relative ' +
											(tabsTitle.isSelected
												? 'border-[3px] border-primary'
												: '')
										}
									>
										{tabsTitle.isSelected && (
											<div className="absolute right-0 flex w-6 h-6 bg-primary rounded-bl-lg justify-center items-center">
												<RiCheckLine className="w-4 h-4 justify-center items-center flex text-custom-white1" />
											</div>
										)}

										<div className="relative w-full h-auto">
											<img
												className="h-32 w-[122px] object-cover rounded z-0"
												alt={tabsTitle.content.title}
												src={tabsTitle.content.image}
											/>
											{TournamentTypeDisplay[
												tournamentStatus!
											] === 'Inscrições aberta' &&
											tabsTitle.content.html ? (
												<div
													className="absolute inset-0 w-full h-full flex flex-col justify-between z-10 "
													dangerouslySetInnerHTML={{
														__html: tabsTitle
															.content.html,
													}}
												/>
											) : TournamentTypeDisplay[
													tournamentStatus!
											  ] !== 'Inscrições aberta' &&
											  tabsTitle.content.htmlClosed ? (
												<div
													className="absolute inset-0 w-full h-full flex flex-col justify-between z-10 "
													dangerouslySetInnerHTML={{
														__html: tabsTitle
															.content.htmlClosed,
													}}
												/>
											) : (
												<div className="absolute inset-0 w-full h-full flex flex-col justify-between z-10">
													<div className="h-full w-full rounded-lg p-1 flex-col justify-end items-start inline-flex ">
														<div className="w-full bg-custom-black/[0.79] px-2.5 py-1 rounded">
															<div className="flex-col justify-center items-start gap-[3px] flex">
																<div className="text-[#fff] font-bahnschrift  text-[10px]">
																	Prêmio de:
																</div>
																<div className="text-[#fff] font-bahnschrift text-[17px]">
																	<span className="flex gap-1 items-center">
																		{showWalletAndBalance() ? (
																			'R$ '
																		) : (
																			<FutProIcon
																				style="w-3.5 h-3.5"
																				color="#ffffff"
																			/>
																		)}
																		{CurrencyFormatter(
																			Number(
																				tabsTitle.prizeAmt
																			)
																		)}
																	</span>
																</div>

																{TournamentTypeDisplay[
																	tournamentStatus!
																] ===
																	'Inscrições aberta' && (
																	<div className="justify-center items-center gap-2.5 inline-flex">
																		<div className="text-[#fff] font-bahnschrift text-[10px] flex items-center gap-1">
																			Participe
																			com{' '}
																			{showWalletAndBalance() ? (
																				'R$ '
																			) : (
																				<FutProIcon
																					style="w-2.5 h-2.5"
																					color="#ffffff"
																				/>
																			)}
																			{CurrencyFormatter(
																				Number(
																					tabsTitle.entryAmt
																				)
																			)}
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
							</a>
						</li>
					))}
				</ul>
			</div>
		);
	}
);

export default CarouselTournament;
