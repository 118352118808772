import { useState } from 'react';
import OptionReplyItemView from './OptionReplyItemView';
import { RiArrowUpSLine } from '@remixicon/react';

export const ReplyOptions = ({ data }: any) => {
	const [open, setOpen] = useState(true);
	return (
		<div className="flex flex-col gap-1.5">
			{open ? (
				<div className="h-[31px] py-[5px] rounded-[9px] border-t border-[#909090] flex-col justify-center items-center gap-2.5 inline-flex transition-all duration-300 ease-in-out">
					<div
						onClick={() => {
							setOpen(!open);
						}}
						className="justify-start items-center gap-2 inline-flex"
					>
						<div className="text-center text-[#909090] text-sm font-semibold font-bahnschrift leading-[21px] tracking-tight">
							Perguntas
						</div>
						<RiArrowUpSLine className="w-4 h-4 " />
					</div>
				</div>
			) : (
				<div className="flex-col justify-center items-center gap-2.5 inline-flex transition-all duration-300 ease-in-out">
					<div
						onClick={() => {
							setOpen(!open);
						}}
						className="w-full justify-center items-center gap-2 inline-flex"
					>
						<div className="text-center text-primary text-sm font-semibold font-bahnschrift leading-[21px] tracking-tight">
							Perguntas
						</div>
						<RiArrowUpSLine className="w-4 h-4 rotate-180 text-primary" />
					</div>
				</div>
			)}

			<div
				className={
					open
						? 'transition-all duration-300 ease-in-out'
						: 'hidden transition-all duration-300 ease-in-out'
				}
			>
				<div className="flex flex-col gap-1.5">
					{data.enrollOption.map((options: any, index: number) => (
						<OptionReplyItemView
							key={index}
							item={options}
							index={options.id}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
