import { useContext, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import { Layout } from '../../components/layout';
import Button from '../../components/ui/button';
import FeedBanner from '../../components/FeedBanner';
import FeedBody from '../../components/FeedBody';
import { sentryCapture } from '../../helpers/utils';
import { ChannelTypes, SentryError } from '../../types/enums';
import { useApi } from '../../hooks/useApi';
import {
	StreamContext,
	useStream,
} from '../../contexts/GetStream/StreamContext';

import banner1 from '../../assets/banners2.png';
import { useFeedUpdateStore } from '../../store/feedUpdate';
import DialogFeed from '../../components/DialogFeed';

export const Feed = () => {
	const api = useApi();
	const { updateFeed, updateFeedFinish } = useStream();
	const { feedupdate, finishUpdateFeed } = useFeedUpdateStore();
	const [feeds, setFeeds] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<any>([]);

	useEffect(() => {
		fetchFeed();
	}, []);

	useEffect(() => {
		if (updateFeed || feedupdate) {
			fetchFeed();
		}
	}, [updateFeed, feedupdate]);

	const fetchFeed = async () => {
		setIsLoading(true);
		try {
			const res = await api.getFeed();
			if (!res?.success) {
				throw new Error(res.message);
			}
			setFeeds(res.data);
			finishUpdateFeed();
		} catch (error: any) {
			sentryCapture(error, 'fetchFeed', SentryError.Error);
		} finally {
			setIsLoading(false);
			updateFeedFinish();
		}
	};

	const feedBanner = feeds.filter(
		(feed: any) => feed.channel === ChannelTypes.HOMEHEAD
	);

	const feedBody = feeds.filter(
		(feed: any) => feed.channel === ChannelTypes.HOMEBODY
	);
	return (
		<Layout>
			<div className="px-0.5 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<FeedBanner data={feedBanner} isLoading={isLoading} />
			</div>

			<div className="px-0.5 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<FeedBody data={feedBody} isLoading={isLoading} />
			</div>

			<DialogFeed />
			<Footer />
		</Layout>
	);
};
