import { TournamentTypeDisplay } from '../../types/enums';

type TagsProps = {
	status: number;
};

const Tags = ({ status }: TagsProps) => {
	const color = (status: number) => {
		switch (status) {
			case 1:
				return 'bg-[#9e0707] text-white';
			case 2:
				return 'bg-[#9e0707] text-white';
			case 3:
				return 'bg-[#0ea25d] text-white';
			default:
				return 'bg-[#e2b500] text-black';
		}
	};

	return (
		<div
			className={
				'h-[30px] max-w-1/2 w-1/2 p-2 rounded-lg justify-center items-center gap-2 inline-flex ' +
				color(status)
			}
		>
			<div className="text-neutral-100 text-sm font-normal font-bahnschrift leading-[14px]">
				{TournamentTypeDisplay[status]}
			</div>
		</div>
	);
};

export default Tags;
