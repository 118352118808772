import { Logo } from '../Logo';
import { RiInstagramLine, RiTwitterXFill } from '@remixicon/react';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const display = Capacitor.isNativePlatform();
	const navigate = useNavigate();

	return (
		<div
			className={
				'w-full bg-footer-light py-6 ' + (display ? 'hidden' : '')
			}
		>
			<div className="flex justify-between items-center px-7 pb-14 lg:max-w-screen-xl lg:mx-auto ">
				<a
					onClick={() => {
						navigate('/');
					}}
					className="cursor-pointer"
				>
					<Logo />
				</a>
				<div className="flex flex-row">
					<a href="#.">
						<RiTwitterXFill
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-4 h-4 ml-3 lg:w-7 lg:h-7"
						/>
					</a>
					<a href="#.">
						<RiInstagramLine
							size={22}
							color="#1d1f24"
							name="ChevronRight"
							className="w-4 h-4 ml-3 lg:w-7 lg:h-7"
						/>
					</a>
				</div>
			</div>
			<div className="px-6 lg:max-w-screen-xl lg:mx-auto">
				<div className="grid grid-cols-2 gap-7 pb-8 lg:grid-cols-6">
					<div className="flex flex-col gap-4">
						<a
							onClick={() => {
								navigate('/termsofuse');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Termos de Uso
						</a>
						<a
							onClick={() => {
								navigate('/privacypolicy');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Política de Privacidade
						</a>
						<a
							onClick={() => {
								navigate('/transparency');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Portal de Transparência
						</a>
					</div>
					<div className="flex flex-col gap-4">
						<a
							onClick={() => {
								navigate('/rules');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Regras
						</a>
						<a
							onClick={() => {
								navigate('/help');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Ajuda e Suporte
						</a>
						<a
							onClick={() => {
								navigate('/termsOfLegalAge');
							}}
							className="cursor-pointer text-sm text-left text-custom-gray1 font-bahnschrift"
						>
							Termo de maior idade
						</a>
					</div>
				</div>
			</div>

			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b col-span-4 mx-6 my-[32px] lg:max-w-screen-xl lg:mx-auto "></div>

			<div className="px-6 lg:max-w-screen-xl lg:mx-auto ">
				<p className="text-sm text-left text-[#6d6d6d] font-bahnschrift">
					Garantindo que cumprimos os mais altos padrões de
					integridade e segurança em nossos serviços. Estamos aqui
					para você, prontos para ajudar e esclarecer qualquer dúvida
					que possa surgir, para que você tenha uma excelente
					experiência conosco!
				</p>
			</div>
		</div>
	);
};

export default Footer;
