import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {
	const navigate = useNavigate();
	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Política de Privacidade
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Obrigado por fazer parte do FUTPRO!{' '}
						</span>
					</p>
					<br />
					A proteção de seus dados é muito importante para nós.
					Exatamente por isso, nesta política de privacidade
					descrevemos, com a transparência que você (usuário) merece,
					como o FUTPRO! trata seus dados, com o objetivo de
					proporcionar a você a melhor experiência e possibilitar a
					oferta de serviços cada vez melhores.
					<br />
					<br />
					Ao utilizar o site www.FUTPRO.com.br (“Site”) e/ou o
					aplicativo FUTPRO (“Aplicativo”), em conjunto, a
					“Plataforma”, você declara estar ciente e de acordo, de
					maneira inequívoca, com todo o conteúdo desta Política de
					Privacidade.
					<br />
					<br />
					Você compreende e concorda de maneira expressa que o FUTPRO
					poderá coletar, armazenar, processar, compartilhar,
					utilizar, divulgar ou de outro modo tratar as suas
					informações, incluindo seus dados pessoais, para todas as
					finalidades previstas nesta Política de Privacidade.
					<br />
					<br />
					Nós tratamos esses dados em conformidade com a legislação
					aplicável, incluindo a Lei nº 13.709, de 14 de agosto de
					2018 - Lei Geral de Proteção de Dados Pessoais (LGPD) e a
					Lei nº 14.790, de 29 de dezembro de 2023 conforme descrito a
					seguir.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							1. Quais dados pessoais são tratados
						</span>
					</p>
					<br />
					Os dados pessoais que o FUTPRO coleta incluem:
					<br />
					<br />
					Dados de registro: aqueles que constam como necessários, de
					acordo com o art. 31 da Portaria nº 1231/2024 da Secretaria
					de Prêmios e Apostas do Ministério da Fazenda, para
					possibilitar a criação de sua conta e utilização dos
					serviços fornecidos pelo FUTPRO. São eles:
					<br />
					<br />
					- Nome completo
					<br />
					- Nacionalidade
					<br />
					- Data de nascimento
					<br />
					- Número do CPF
					<br />
					- Endereço de e-mail
					<br />
					- Endereço completo
					<br />
					- Número de telefone
					<br />
					- Cópia digitalizada de documento de identificaçãocom foto
					<br />
					<br />
					Dados de autenticação: aqueles que você fornece para
					comprovar sua identidade e utilizar os serviços na
					plataforma do FUTPRO. São eles:
					<br />
					<br />
					- Reconhecimento facial com prova de vida
					<br />
					<br />
					Dados de pagamento: aqueles que você fornece para realizar
					depósitos ou saques no FUTPRO. São eles:
					<br />
					<br />
					- Nome completo
					<br />
					- Data de nascimento
					<br />
					- Informações bancárias – como banco, agência, conta ou
					chave PIX
					<br />
					<br />
					Dados de utilização dos serviços: aqueles que você fornece
					quando está efetivamente utilizando o FUTPRO. São dados
					técnicos que podem incluir:
					<br />
					<br />
					- Informações de URL
					<br />
					- Cookies
					<br />
					- Endereço de IP
					<br />
					- Tipo de dispositivo utilizado para acessar o FUTPRO
					<br />
					- Identificações exclusivas do dispositivo
					<br />
					- Tipo de ligação à Internet (Wi-Fi, 3G, LTE, Bluetooth)
					<br />
					- Fornecedor de rede
					<br />
					- Desempenho da rede
					<br />
					- Navegador utilizado
					<br />
					- Sistema operacional
					<br />
					- Geolocalização
					<br />
					<br />
					Usuários visitantes: Quando você visita o site FUTPRO, as
					seguintes informações são coletadas: (i) endereço IP; (ii)
					tipo de navegador; (iii) informações sobre o dispositivo
					móvel ou computador; (iv) geolocalização; (v) páginas
					visitadas no website ou aplicativo; (vi) duração da visita;
					(vii) caminhos de visualização do site; e (viii)
					visualizações da página.
					<br />
					<br />
					Com base na Portaria nº 722/2024 da Secretaria de Prêmios e
					Apostas do Ministério da Fazenda, o FUTPRO coleta dados de
					geolocalização, a fim de aumentar a segurança da plataforma
					e evitar o uso em jurisdições não autorizadas pela
					regulamentação brasileira.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							2. Para quais finalidades os seus dados pessoais são
							tratados
						</span>
					</p>
					<br />
					O FUTPRO pode utilizar as suas informações para as seguintes
					finalidades:
					<br />
					<br />
					prestar, fornecer, manter, desenvolver, aprimorar, divulgar
					e ampliar a utilização dos Serviços;
					<br />
					divulgar seus resultados a cada rodada de campeonato;
					<br />
					identificar você e personalizar a sua experiência e
					utilização dos Serviços;
					<br />
					realizar o seu cadastro em nossas plataformas;
					<br />
					possibilitar a comunicação com você, diretamente, ou por
					meio de provedores de serviços, para envio de publicidade
					por e-mail ou de notificações no FUTPRO App, divulgando e
					promovendo nossos Serviços, promoções, sorteios e outros
					benefícios, além de oferecendo eventuais novos produtos;
					cruzar suas informações com os dados de pagamento quando
					você nos pede para converter seus créditos do Aplicativo em
					dinheiro, também para fins de processamento do pagamento;
					<br />
					classificar e agrupar informações e consolidar informações
					estatísticas;
					<br />
					possibilitar que a FUTPRO entre em contato com você a
					respeito dos Serviços que disponibiliza, bem como para
					prestar suporte a você quando solicitado;
					<br />
					disponibilizar e melhorar mecanismos de segurança dos
					Serviços das Plataformas;
					<br />
					detectar e proteger você e as Plataformas contra fraudes,
					abusos ou atos ilegais;
					<br />
					prevenir situações de duplicidade ou uso fraudulento de
					contas no FUTPRO;
					<br />
					garantir aos usuários do FUTPRO o cumprimento de seus
					direitos enquanto titulares de dados pessoais;
					<br />
					possibilitar o envio de eventuais brindes aos usuários do
					FUTPRO;
					<br />
					gerar análises e relatórios estatísticos sobre o
					funcionamento e operação do FUTPRO, em benefício do FUTPRO
					e/ou dos seus parceiros;
					<br />
					desenvolver novos produtos, serviços e funcionalidades;
					<br />
					dar cumprimento a obrigações legais e regulatórias do
					FUTPRO, na forma da legislação e regulamentação aplicáveis;
					<br />
					informar órgãos e entidades reguladoras sobre as atividades
					realizadas, quando necessário;
					<br />
					exercer nossos direitos em processo judicial, administrativo
					ou arbitral.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							3. Como os seus dados pessoais são tratados
						</span>
					</p>
					<br />
					Os seus dados pessoais são coletados, acessados,
					armazenados, classificados, utilizados e, na medida do
					necessário, de outras formas tratados pelo FUTPRO para as
					finalidades previstas nesta Política.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							3.1. Com quem seus dados são compartilhados?
						</span>
					</p>
					<br />
					‍Seus dados poderão ser compartilhados com terceiros, para
					melhor execução e continuidade dos serviços fornecidos,
					conforme descrito abaixo:
					<br />
					<br />
					- Alteração do controle societário do FUTPRO
					<br />
					Caso o FUTPRO passe por alterações societárias, tais como
					transferência de quotas/ações, reestruturação, fusão, cisão
					ou qualquer outra operação que implique na alteração do
					controle societário, os dados fornecidos por você poderão
					ser transferidos na operação, sempre sendo respeitado o
					disposto nesta Política de Privacidade.
					<br />
					<br />
					- Provedores de serviço
					<br />
					a. Seus dados poderão ser compartilhados com nossos
					provedores de serviços, contratados para facilitar e
					otimizar as atividades do FUTPRO e as funcionalidades
					oferecidas em nossa plataforma;
					<br />
					b. Nossos provedores de serviços incluem, sem se limitar, a
					empresas de armazenamento de dados em cloud, gerenciamento
					de banco de dados, análise de dados, melhoria de
					funcionalidades e da experiência do usuário;
					<br />
					c. Quando você efetua um depósito ou saque em uso da
					plataforma do FUTPRO, os dados de pagamento serão
					compartilhados com as empresas que processam e liquidam as
					transações, com a finalidade exclusiva de viabilizar essas
					operações;
					<br />
					d. Em todo compartilhamento dos seus dados com nossos
					provedores de serviço, as informações serão utilizadas
					exclusivamente para a finalidade acordada com o FUTPRO,
					ficando os provedores obrigados ao cumprimento das
					diretrizes desta Política de Privacidade.
					<br />
					<br />
					- Conflitos judiciais
					<br />
					Em caso de conflito envolvendo os serviços disponibilizados
					pelo FUTPRO, este poderá, nos termos da legislação vigente,
					utilizar seus dados em ação judicial para promover a
					ressalva dos seus direitos.Ordem judicial ou administrativa
					<br />
					a. O FUTPRO poderá compartilhar seus dados para atender
					ordem judicial ou administrativa legitimamente expedida por
					autoridade competente, nos termos da legislação vigente.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							3.2. Como seus dados são armazenados?
						</span>
					</p>
					<br />
					‍As Plataformas armazenarão os seus dados pessoais pelo
					tempo necessário para o alcance das finalidades descritas
					nesta Política de Privacidade e em observância à legislação
					aplicável ou cumprimento de ordem judicial.
					<br />
					<br />
					As suas informações pessoais poderão ser armazenadas por
					empresa terceira localizada no exterior, que garante cumprir
					com a Lei Geral de Proteção de Dados, e em linha com o que é
					exigido pela Lei 14.790/2023.
					<br />
					<br />
					Realizamos a transferência internacional de seus dados
					pessoais para armazenamento em cloud hospedada em servidores
					no exterior. Tais serviços de cloud são contratados
					especificamente em razão de sua boa reputação e do alto grau
					de segurança proporcionado aos dados que lhe são confiados,
					sendo exigido destes o cumprimento do disposto nesta
					Política de Privacidade.
					<br />
					<br />
					As Plataformas utilizam de criptografia para proteção de
					suas informações.
					<br />
					<br />
					Os funcionários, consultores, agentes ou subcontratados das
					Plataformas terão acesso às suas informações pessoais apenas
					quando necessário para prestação de serviço ou cumprimento
					de obrigação legal ou contratual.
					<br />
					<br />
					O FUTPRO está comprometido com a segurança das suas
					informações pessoais durante todo o processo de navegação na
					Plataforma e prestação dos Serviços. Por isso, as
					informações pessoais serão mantidas no mais estrito sigilo e
					não serão vendidas, trocadas ou divulgadas a quaisquer
					terceiros, salvo nas hipóteses expressamente estabelecidas
					nesta Política.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							4. Seus direitos e nossas responsabilidades
						</span>
					</p>
					<br />
					O FUTPRO preza pela transparência na relação com os
					titulares de dados pessoais e pelo cumprimento da legislação
					aplicável. Em relação aos dados pessoais que você fornece ao
					preencher o formulário, a lei assegura a você o direito de:
					<br />
					<br />
					Ter acesso facilitado a informações claras, precisas e
					atualizadas sobre o tratamento dos seus dados pessoais e
					sobre os respectivos agentes de tratamento;
					<br />
					Solicitar a confirmação de que seus dados pessoais estejam
					sendo efetivamente tratados pelo FUTPRO;
					<br />
					Solicitar acesso os seus dados pessoais que sejam mantidos
					pelo FUTPRO;
					<br />
					Solicitar a correção de seus dados caso estejam incompletos,
					inexatos ou desatualizados;
					<br />
					Solicitar a anonimização, bloqueio ou eliminação de seus
					dados, caso sejam desnecessários, excessivos ou tratados
					pelo FUTPRO em desconformidade com o disposto na LGPD;
					<br />
					Solicitar a portabilidade dos seus dados pessoais a outro
					fornecedor de serviço ou produto, de acordo com
					regulamentação da ANPD - Autoridade Nacional de Proteção de
					Dados, observados os segredos comercial e industrial do
					FUTPRO; Solicitar informações sobre as entidades com as
					quais o FUTPRO realize uso compartilhado de seus dados
					pessoais;
					<br />
					Opor-se ao tratamento dos seus dados pessoais pelo FUTPRO
					com fundamento em uma das hipóteses de dispensa de
					consentimento, em caso de descumprimento ao disposto da
					LGPD;
					<br />
					Solicitar informações sobre a possibilidade de não fornecer
					consentimento para o tratamento dos seus dados pessoais e
					sobre as respectivas consequências;
					<br />
					Revogar o consentimento dado ao FUTPRO para o tratamento dos
					seus dados pessoais, ressalvado o tratamento em conformidade
					com outras bases legais;
					<br />
					Solicitar a eliminação dos dados pessoais tratados pelo
					FUTPRO com base no seu consentimento, ressalvada a
					conservação para o cumprimento de obrigação legal ou
					regulatória a nós atribuída, transferência a terceiro nos
					limites da legislação aplicável e utilização após
					anonimização; Peticionar em relação aos seus dados pessoais
					contra o FUTPRO perante a ANPD - Autoridade Nacional de
					Proteção de Dados.
					<br />
					<br />
					Somos responsáveis pelo cumprimento das normas legais
					aplicáveis ao tratamento dos seus dados pessoais e pela
					reparação de eventual dano causado na hipótese de sua
					inobservância, nos limites da lei. Conforme previsto em lei,
					não seremos de nenhuma forma responsáveis se não tivermos
					realizado o tratamento de dados pessoais a nós atribuídos,
					se não houver violação à legislação de proteção de dados
					pelo FUTPRO ou se o eventual dano decorrer de culpa
					exclusiva sua ou de terceiros.
					<br />
					<br />
					Antes de respondermos a uma solicitação para exercício dos
					direitos mencionados acima, podemos solicitar que você nos
					forneça algumas informações para confirmarmos sua
					identidade.
					<br />
					<br />
					Em observância à legislação aplicável ou cumprimento de
					ordem judicial, o FUTPRO poderá manter determinados dados
					seus armazenados, mesmo após o pedido de exclusão por você.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							5. Cookies
						</span>
					</p>
					<br />
					O que são cookies?
					<br />
					a. São arquivos de texto que armazenam e reconhecem dados da
					sua navegação para garantir o correto funcionamento do
					Aplicativo e para proporcionar experiências otimizadas na
					utilização dos serviços
					<br />
					<br />
					Finalidades dos cookies:
					<br />
					‍a. Descrevemos abaixo algumas finalidades da coleta de
					cookies em nosso App. São elas:
					<br />
					<br />
					Funcionamento: Para acesso e o funcionamento do FUTPRO App,
					compreendidas todas as suas funcionalidades.
					<br />
					Autenticação: Para reconhecer você, possibilitando o seu
					acesso às informações e dados que só podem ser por este
					acessadas.
					<br />
					Segurança: Para auxiliar no monitoramento e detecção de
					atividades não autorizadas, prevenção a fraudes e proteção
					das suas informações.
					<br />
					Pesquisa, Análise e Desempenho: para verificar, medir e
					analisar a audiência, o desempenho e a utilização do App por
					você.
					<br />
					<br />
					Desativação dos cookies:
					<br />
					a. Você pode desativar ou excluir os cookies nas
					configurações do seu navegador e nas configurações do
					sistema operacional do seu dispositivo, com exceção dos
					cookies de funcionamento que, se desativados, não permitirão
					o uso do Aplicativo.
					<br />
					<br />
					b. Além disso, a depender do sistema operacional do seu
					dispositivo, você poderá autorizar e/ou cancelar o acesso do
					FUTPRO a determinadas informações (como dados de arquivos,
					por exemplo). É importante esclarecer, no entanto, que se os
					acessos não forem concedidos por você ou dependendo da
					limpeza de cookies, o FUTPRO poderá não funcionar
					corretamente.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							6. Alterações nos termos de serviço ou na Política
							de Privacidade
						</span>
					</p>
					<br />
					Você será avisado sobre qualquer alteração nos Termos de
					Serviço ou Política de Privacidade mediante envio de e-mail
					ao endereço de e-mail cadastrado na Plataforma.
					<br />
					<br />
					Caso você não concorde com as alterações nos Termos de Uso
					ou Política de Privacidade, você poderá encerrar a sua
					conta. Caso você não encerre a sua conta, você estará de
					acordo com os novos Termos de Serviço e Política de
					Privacidade, que passarão a reger o tratamento dos dados nas
					Plataformas.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							7. Como entrar em contato com FUTPRO
						</span>
					</p>
					<br />
					Caso você tenha qualquer dúvida ou solicitação relacionada a
					esta Política ou ao tratamento dos dados pessoais que você
					fornece ao preencher o nosso formulário de registro no
					FUTPRO, entre em contato através do nosso Canal da
					Transparência, disponível no endereço eletrônico{' '}
					<span
						onClick={() => {
							navigate('/help');
						}}
						className="cursor-pointer font-bold"
					>
						www.futpro.com.br/help
					</span>
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
