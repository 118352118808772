import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';

export const LegalAge = () => {
	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Termo de Maior Idade
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Termo de Maior Idade.{' '}
						</span>
					</p>
					<br />
					Declaro, para todos os efeitos legais, que sou maior de
					idade (18 anos ou mais) e plenamente capaz de exercer meus
					direitos civis. Assumo total responsabilidade pela
					veracidade, exatidão, validade e autenticidade das
					informações fornecidas neste cadastro, comprometendo-me a
					mantê-las atualizadas sempre que necessário.
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
