import { Layout } from '../../../components/layout';
import { useTokenGetStreamStore } from '../../../store/token';
import { useUserStore } from '../../../store/user';
import { useNotificationStore } from '../../../store/notification';
import React, { useEffect, useState } from 'react';
import { sentryCapture, showWalletAndBalance } from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { clientChat } from '../../../contexts/GetStream/StreamContext';
import Button from '../../../components/ui/button';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import { useNavigate } from 'react-router-dom';
import { FutProIcon } from '../../../components/Icons/FutProIcon';

export const Notification = () => {
	const navigate = useNavigate();
	const notificationCount = useNotificationStore(
		(state) => state.notification
	);
	const { clearNotification } = useNotificationStore();
	const tokenGetStream = useTokenGetStreamStore(
		(state) => state.tokenGetStream
	);
	const user = useUserStore((state) => state.user);
	const [isLoading, setIsLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [feed, setFeed] = useState<any>([]);
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (user) {
			loadMoreNotifications();
		}
	}, []);

	useEffect(() => {
		if (notificationCount > 0) {
			clearNotification();
		}
	}, [notificationCount]);

	const loadMoreNotifications = async () => {
		if (isLoading) return;
		setIsLoading(true);

		try {
			await clientChat.connectUser(
				{ id: user?.accountId! },
				tokenGetStream
			);
			const chat = clientChat.channel('news', `news-${user?.accountId}`);
			await chat.watch();
			if (chat.initialized) {
				await chat.markRead({ user_id: user?.accountId });
			}

			const totalMessages = chat.state.messageSets[0].messages.length;
			const newOffset = page * 3;

			if (newOffset >= totalMessages) {
				setHasMore(false);
				return;
			}

			const chatFeed = await chat.query(
				{
					messages: {
						limit: 3,
						offset: newOffset,
					},
				},
				'new'
			);
			const sortedMessages = chatFeed.messages.reverse();
			setFeed((prevFeed: any) => [...prevFeed, ...sortedMessages]);
			setPage((prevPage) => prevPage + 1);
			chat.on('message.new', (event) => {
				setFeed((prevFeed: any) => [event.message, ...prevFeed]);
			});
		} catch (err: any) {
			sentryCapture(err, 'notification-feed', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleLoadMore = () => {
		loadMoreNotifications();
	};

	return (
		<Layout>
			<div className="w-full h-8 pt-8 pb-[22px] px-3.5 lg:max-w-screen-xl lg:mx-auto">
				<p className="w-full text-xl font-semibold text-center text-custom-gray1 font-bahnschrift">
					Notificações
				</p>
			</div>

			<div className="py-[22px] px-3.5 lg:max-w-screen-xl lg:mx-auto">
				{feed && feed.length > 0 ? (
					feed.map((notification: any, index: number) => (
						<div
							key={index}
							onClick={() => {
								if (JSON.parse(notification.text).enrollId) {
									return navigate(
										`/enroll/${
											JSON.parse(notification.text)
												.enrollId
										}`
									);
								} else {
									return;
								}
							}}
							className="flex flex-col rounded-lg bg-custom-gray6 p-4 gap-2 mb-6"
						>
							<p className="text-sm text-left text-custom-gray2 font-bahnschrift">
								{format(
									notification.created_at,
									'dd/MM/yyyy HH:mm',
									{
										locale: ptBR,
									}
								)}
							</p>

							<p className="text-base text-left text-custom-gray2 font-bahnschrift break-words">
								<i
									className={`${
										JSON.parse(notification.text).icon
									} ri-1x`}
								></i>{' '}
								{showWalletAndBalance()
									? JSON.parse(
											notification.text
									  ).text.replace(/\n/g, '<br>')
									: JSON.parse(notification.text)
											.text.split('R$')
											.map((part: any, index: number) => (
												<React.Fragment key={index}>
													{part
														.split('\n')
														.map(
															(
																line: any,
																lineIndex: number
															) => (
																<React.Fragment
																	key={
																		lineIndex
																	}
																>
																	{line}
																	{lineIndex <
																		part.split(
																			'\n'
																		)
																			.length -
																			1 && (
																		<br />
																	)}
																</React.Fragment>
															)
														)}
													{index <
														JSON.parse(
															notification.text
														).text.split('R$')
															.length -
															1 && (
														<FutProIcon
															style="w-3.5 h-3.5 inline-block align-middle"
															color="#000000"
														/>
													)}
												</React.Fragment>
											))}
							</p>
							<p className="text-sm text-left text-custom-gray4 uppercase font-bahnschrift">
								#{notification?.id}
							</p>
						</div>
					))
				) : isLoading ? (
					<div className="flex items-center justify-center"></div>
				) : (
					<div>Sem notificações.</div>
				)}

				{hasMore && (
					<Button
						isLoading={isLoading}
						onClick={handleLoadMore}
						className="w-full px-3.5 py-3.5"
					>
						Carregar mais
					</Button>
				)}
			</div>
		</Layout>
	);
};
