import { useState, useRef, useEffect } from 'react';

interface TabsProps {
	initialPage: string;
	render: {
		title: string;
		renderPage: () => JSX.Element;
	}[];
}

export const Tabs = ({ render, initialPage }: TabsProps) => {
	const [openTab, setOpenTab] = useState(initialPage);
	const tabListRef = useRef<HTMLUListElement>(null);
	const activeTabRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (activeTabRef.current && tabListRef.current) {
			const activeTab = activeTabRef.current;
			const tabList = tabListRef.current;
			const activeTabRect = activeTab.getBoundingClientRect();
			const tabListRect = tabList.getBoundingClientRect();

			if (activeTabRect.left < tabListRect.left) {
				tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
			} else if (activeTabRect.right > tabListRect.right) {
				tabList.scrollLeft += activeTabRect.right - tabListRect.right;
			}
		}
	}, [openTab]);

	let isDragging = false;
	let startX: number;
	let scrollLeft: number;

	const handleMouseDown = (e: React.MouseEvent) => {
		isDragging = true;
		startX = e.pageX - (tabListRef.current?.offsetLeft || 0);
		scrollLeft = tabListRef.current?.scrollLeft || 0;
		document.body.style.userSelect = 'none';
	};

	const handleMouseLeave = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};

	const handleMouseUp = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - (tabListRef.current?.offsetLeft || 0);
		const walk = (x - startX) * 2;
		if (tabListRef.current) {
			tabListRef.current.scrollLeft = scrollLeft - walk;
		}
	};

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto lg:pt-4">
			<ul
				className="flex mb-0 list-none py-3 px-4 flex-row overflow-x-auto whitespace-nowrap scrollbar-hide "
				role="tablist3"
				ref={tabListRef}
				onMouseDown={handleMouseDown}
				onMouseLeave={handleMouseLeave}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
			>
				{render.map((tabsTitle, index) => (
					<li
						key={index}
						className={
							'flex gap-2 px-3.5 py-2 rounded-lg flex-shrink-0 cursor-pointer select-none font-bahnschrift transition-all duration-700 transform ' +
							(openTab === tabsTitle.title
								? 'bg-custom-gray translate-x-0'
								: 'text-custom-gray4')
						}
						ref={openTab === tabsTitle.title ? activeTabRef : null}
					>
						<a
							className={
								'text-sm px-5 font-semibold text-left font-bahnschrift ' +
								(openTab === tabsTitle.title
									? 'text-primary'
									: 'text-custom-gray4')
							}
							onClick={(e) => {
								e.preventDefault();
								setOpenTab(tabsTitle.title);
							}}
							data-toggle="tab"
							href={`#${tabsTitle.title}`}
							role="tablist3"
							aria-selected={openTab === tabsTitle.title}
						>
							{tabsTitle.title}
						</a>
					</li>
				))}
			</ul>

			<div className="h-0 border-solid border-custom-gray3 opacity-25 border-b w-full mb-[29px] lg:hidden"></div>

			<div className="relative flex flex-col min-w-0 break-words w-full">
				<div className="px-4 flex-auto">
					{render.map((tabsRender, index) => (
						<div className="tab-content tab-space" key={index}>
							<div
								className={
									openTab === tabsRender.title
										? 'block'
										: 'hidden'
								}
								id={tabsRender.title}
							>
								{tabsRender.renderPage()}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
