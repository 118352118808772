import { useState, useRef } from 'react';
import { useAuthContext } from '../../contexts/Auth/AuthContext';
import { useModalLoginStore } from '../../store/modalLogin';

interface TabsProps {
	initialPage: string;
	onChangeTab: (tab: string) => void;
	render: {
		title: string;
		isLoggedRequired: boolean;
		renderPage: () => JSX.Element;
	}[];
}

export const TabsButton = ({ render, initialPage, onChangeTab }: TabsProps) => {
	const [openTab, setOpenTab] = useState(initialPage);
	const renderedTabs = useRef<string[]>([]);
	const { user } = useAuthContext();
	const { openModalLogin } = useModalLoginStore();

	const handleTabClick = (tab: string) => {
		setOpenTab(tab);
		onChangeTab(tab);
		if (!renderedTabs.current.includes(tab)) {
			renderedTabs.current.push(tab);
		}
	};

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto lg:pt-4 gap-4">
			<ul
				className="w-full flex flex-row items-center mb-0 list-none rounded-lg border border-primary"
				role="tablist4"
			>
				{render
					.filter((i) => !i.isLoggedRequired)
					.map((tabsTitle: any, index: number) => (
						<li
							key={index}
							className={
								'flex items-center justify-center gap-2 px-3.5 py-2 flex-shrink-0 cursor-pointer select-none font-bahnschrift transition-all duration-700 transform ' +
								(openTab === tabsTitle.title
									? 'bg-primary translate-x-0 '
									: 'text-[#696969] ') +
								(render[0].title === tabsTitle.title
									? 'rounded-tl rounded-bl '
									: 'rounded-tr rounded-br ') +
								(render.filter((i) => !i.isLoggedRequired)
									.length > 1
									? 'w-1/2'
									: 'w-full')
							}
							onClick={() => {
								if (!user && tabsTitle.isLoggedRequired) {
									openModalLogin(true, `/tournaments`);
									return;
								}
								handleTabClick(tabsTitle.title);
							}}
						>
							<a
								className={
									'text-sm px-2 font-semibold text-left font-bahnschrift ' +
									(openTab === tabsTitle.title
										? 'text-white'
										: 'text-[#696969]')
								}
								href={`#${tabsTitle.title}`}
								role="tablist4"
								aria-selected={openTab === tabsTitle.title}
							>
								{tabsTitle.title}
							</a>
						</li>
					))}
			</ul>

			<div className="relative flex flex-col min-w-0 break-words w-full">
				<div className="flex-auto">
					{render.map((tabsRender: any, index: number) => (
						<div className="tab-content tab-space" key={index}>
							<div
								className={
									openTab === tabsRender.title
										? 'block'
										: 'hidden'
								}
								id={tabsRender.title}
							>
								{openTab === tabsRender.title ||
								renderedTabs.current.includes(tabsRender.title)
									? tabsRender.renderPage()
									: null}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
