import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from 'sonner';
import { RiCheckLine } from '@remixicon/react';
import { RegisterSchema } from '../../../../types/usersValidation';
import TextInput from '../../../ui/TextInput';
import Button from '../../../ui/button';
import { useApi } from '../../../../hooks/useApi';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import CheckboxIcon from '../../../ui/CheckBox';
import { useAuthContext } from '../../../../contexts/Auth/AuthContext';
import Toast from '../../../ui/toast';
import { helpButton } from '../../../ui/helpButton';
import PasswordCaption from '../../../PasswordCaption';
import { useNavigate } from 'react-router-dom';
import { useCookieContext } from '../../../../contexts/Cookies/CookiesContext';
import { Warning } from '../../../Icons/Warning';
import Modal from '../../../Modal/modal';

const RegisterForms = ({ openModal, onClose }: any) => {
	const api = useApi();
	const navigate = useNavigate();
	const { register } = useAuthContext();
	const { handleAccept } = useCookieContext();
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [openMVP, setOpenMVP] = useState(false);
	const [nickSuggestion, setNickSuggestion] = useState([]);
	const [activeStep, setActiveStep] = useState(1);
	const step2 = useRef<HTMLInputElement>(null);
	const step3 = useRef<HTMLInputElement>(null);
	const step4 = useRef<HTMLInputElement>(null);
	const step5 = useRef<HTMLInputElement>(null);

	const [timeLeft, setTimeLeft] = useState(120);
	const [canResend, setCanResend] = useState(false);

	useEffect(() => {
		if (timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft(timeLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			setCanResend(true);
		}
	}, [timeLeft]);

	const steps = [
		{ step: 1, title: 'Informe o seu apelido' },
		{ step: 2, title: 'Informe a senha que deseja' },
		{ step: 3, title: 'Confirme a senha' },
		{ step: 4, title: 'Informe o seu e-mail' },
		{ step: 5, title: 'Informe o código de verificação' },
		{ step: 6, title: 'Termos e Condições' },
	];

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		trigger,
	} = useForm<z.infer<typeof RegisterSchema>>({
		resolver: zodResolver(RegisterSchema),
		mode: 'onChange',
		defaultValues: {
			email: '',
			nick: '',
			password: '',
			confirmPassword: '',
			termsOfUse: false,
			termsOfLegalAge: false,
			termsPrivacyPolicy: false,
		},
	});

	useEffect(() => {
		if (activeStep === 2 && step2.current) {
			step2.current.focus();
		}
		if (activeStep === 3 && step3.current) {
			step3.current.focus();
		}
		if (activeStep === 4 && step4.current) {
			step4.current.focus();
		}
		if (activeStep === 5 && step5.current) {
			step5.current.focus();
		}
	}, [activeStep]);

	useEffect(() => {
		const savedValues = localStorage.getItem('registerFormData');
		if (savedValues) {
			const parsedValues = JSON.parse(savedValues);
			Object.keys(parsedValues).forEach((key) => {
				setValue(
					key as keyof z.infer<typeof RegisterSchema>,
					parsedValues[key]
				);
			});
		}

		const savedStep = localStorage.getItem('registerFormStep');
		if (savedStep) {
			setActiveStep(parseInt(savedStep, 10));
		}
	}, [setValue]);

	useEffect(() => {
		const subscription = watch((value) => {
			localStorage.setItem('registerFormData', JSON.stringify(value));
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		if (activeStep !== 1) {
			localStorage.setItem('registerFormStep', activeStep.toString());
		}
	}, [activeStep]);

	const emailValue: any = watch('email').toLowerCase();

	const nextStep = async (form: any, step: number) => {
		const valid = await trigger(form);
		if (valid) {
			setActiveStep(step + 1);
		}
	};

	const handleRegister = async (data: z.infer<typeof RegisterSchema>) => {
		setIsLoading(true);
		try {
			const newRegister = await register(data);
			if (!newRegister?.success) {
				throw new Error(newRegister.message);
			}
			onClose();
			handleAccept();
			localStorage.removeItem('registerFormData');
			localStorage.removeItem('registerFormStep');
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const validateNewEmail = async (step: number, email: string) => {
		setIsLoading(true);
		try {
			const findEmail = await api.validateNewEmailRegister(emailValue);
			if (!findEmail?.success) {
				if (
					findEmail.message ===
					'Apenas usuarios Beta podem se cadastrar, aguarde seu convite.'
				) {
					setOpenMVP(!openMVP);
					return;
				}
				throw new Error(findEmail.message);
			}
			setActiveStep(step + 1);
			sendTokenValidation();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const [sendToken, setSendToken] = useState(false);
	const sendTokenValidation = async () => {
		if (sendToken) {
			console.log('aqui');
			return;
		}
		try {
			setCanResend(true);
			setSendToken(true);
			const loginToken = await api.sendTokenValidation(emailValue);

			if (!loginToken?.success) {
				throw new Error(loginToken.message);
			}
			setTimeLeft(120);
			setCanResend(false);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setSendToken(false);
		}
	};

	const validateVerificationEmailToken = async (
		step: number,
		code: string
	) => {
		if (code === '' || code.trim() === '') {
			toast(
				<Toast variant="error">Informe o token para continuar.</Toast>
			);
			return;
		}
		if (code.length < 6) {
			toast(<Toast variant="error">Informe um código válido.</Toast>);
			return;
		}
		setIsLoading(true);
		try {
			const loginToken = await api.validateVerificationEmailToken(
				emailValue,
				code
			);
			if (!loginToken?.success) {
				throw new Error(loginToken.message);
			}
			setActiveStep(step + 1);
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const validateNewNick = async (step: number, nick: string) => {
		setIsLoading(true);
		try {
			const findNick = await api.validateNewNick(nick);
			if (!findNick?.success) {
				setNickSuggestion(findNick.suggestions);
				throw new Error(findNick.message);
			}
			setActiveStep(step + 1);
		} catch (error: any) {
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const stepForm = (step: number) => {
		switch (step) {
			case 1:
				return (
					<Controller
						control={control}
						name="nick"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									mask="@***************"
									placeholder="Apelido"
									value={value}
									error={errors?.nick?.message}
									onChange={onChange}
									ref={step3}
									autoFocus
								/>

								{nickSuggestion.length > 0 && (
									<div>
										<p className="text-xs py-1 text-left text-custom-gray3">
											Apelido não disponivel... aceita
											sugestões?
										</p>

										<div className="grid grid-cols-4 gap-1">
											{nickSuggestion.map(
												(
													nick: string,
													index: number
												) => (
													<p
														key={index}
														onClick={() => {
															setValue(
																'nick',
																nick
															);
														}}
														className="text-xs cursor-pointer font-semibold text-left text-primary"
													>
														{nick}
													</p>
												)
											)}
										</div>
									</div>
								)}
								<div className="flex gap-1 mt-5">
									<Button
										onClick={() =>
											validateNewNick(step, value)
										}
										disabled={!value || !!errors?.nick}
										isLoading={isLoading}
										className="px-3.5 py-1.5 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 2:
				return (
					<Controller
						control={control}
						name="password"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<div className="flex flex-col gap-3">
									<TextInput
										type="password"
										placeholder="Digite a senha"
										value={value}
										onChange={onChange}
										ref={step4}
										autoFocus
									/>
									<PasswordCaption value={value} />
								</div>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										onClick={() =>
											nextStep('password', step)
										}
										disabled={!value || !!errors?.password}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 3:
				return (
					<Controller
						control={control}
						name="confirmPassword"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="password"
									placeholder="Digite a senha"
									value={value ?? ''}
									error={errors?.confirmPassword?.message}
									onChange={onChange}
									ref={step5}
									autoFocus
								/>
								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										onClick={() =>
											nextStep('confirmPassword', step)
										}
										disabled={
											!value || !!errors?.confirmPassword
										}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 4:
				return (
					<Controller
						control={control}
						name="email"
						render={({ field: { value, onChange } }) => (
							<div className="py-3">
								<TextInput
									type="text"
									placeholder="E-mail"
									value={value}
									error={errors?.email?.message}
									onChange={onChange}
									classNameCustom="lowercase"
								/>

								<div className="flex gap-2">
									<Button
										variant="destructive"
										onClick={() => setActiveStep(step - 1)}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Voltar
									</Button>
									<Button
										onClick={() => {
											validateNewEmail(step, value);
										}}
										disabled={!value || !!errors?.email}
										isLoading={isLoading}
										className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
										textCustom="text-sm"
									>
										Próximo
									</Button>
								</div>
							</div>
						)}
					/>
				);
			case 5:
				return (
					<div>
						<p className="text-xs text-left text-custom-gray3 pb-2 font-bahnschrift">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Enviamos um código de verificação para o e-mail{' '}
							</span>
							<span className="text-xs font-semibold text-left text-custom-gray3 font-bahnschrift">
								{emailValue}
							</span>
						</p>

						<TextInput
							type="text"
							placeholder="Digite o código"
							inputMode="tel"
							value={code}
							onChange={(e) => {
								setCode(e);
							}}
							ref={step2}
							autoFocus
						/>

						<p className="text-sm text-left">
							<span className="text-xs text-left text-custom-gray3 font-bahnschrift">
								Não recebeu o código?{' '}
							</span>

							{canResend ? (
								<span
									onClick={() => sendTokenValidation()}
									className="text-xs font-semibold text-left text-primary cursor-pointer"
								>
									Clique aqui para reenviar
								</span>
							) : (
								<span className="text-xs font-semibold text-left text-primary cursor-pointer">
									Reenvie em {Math.floor(timeLeft / 60)}:
									{('0' + (timeLeft % 60)).slice(-2)}
								</span>
							)}
						</p>

						<div className="flex gap-2">
							<Button
								variant="destructive"
								onClick={() => setActiveStep(step - 1)}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Voltar
							</Button>
							<Button
								onClick={() =>
									validateVerificationEmailToken(step, code)
								}
								isLoading={isLoading}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Próximo
							</Button>
						</div>
					</div>
				);
			case 6:
				return (
					<div className="py-3">
						<Controller
							control={control}
							name="termsOfLegalAge"
							render={({ field: { value, onChange } }) => (
								<div className="flex flex-row mb-2 gap-2 items-center">
									<CheckboxIcon
										checked={value}
										onCheckedChange={onChange}
									/>
									<p
										className={
											'text-xs text-left font-bahnschrift ' +
											(errors.termsOfLegalAge
												? 'text-error'
												: 'text-custom-gray2')
										}
									>
										Confirmo que sou{' '}
										<span
											className="underline cursor-pointer"
											onClick={() => {
												localStorage.setItem(
													'goToTerms',
													'true'
												);
												navigate('/termsOfLegalAge');
											}}
										>
											maior de 18 anos
										</span>
									</p>
								</div>
							)}
						/>
						<div className="flex flex-col">
							<Controller
								control={control}
								name="termsOfUse"
								render={({ field: { value, onChange } }) => (
									<div className="flex flex-row mb-2 gap-2 items-center">
										<CheckboxIcon
											checked={value}
											onCheckedChange={onChange}
										/>
										<p
											className={
												'text-xs text-left font-bahnschrift ' +
												(errors.termsOfUse
													? 'text-error'
													: 'text-custom-gray2')
											}
										>
											Aceito os{' '}
											<span
												className="underline cursor-pointer"
												onClick={() => {
													localStorage.setItem(
														'goToTerms',
														'true'
													);
													navigate('/termsofuse');
												}}
											>
												termos de uso
											</span>
										</p>
									</div>
								)}
							/>
						</div>

						<Controller
							control={control}
							name="termsPrivacyPolicy"
							render={({ field: { value, onChange } }) => (
								<div className="flex flex-row mb-2 gap-2 items-center">
									<CheckboxIcon
										checked={value}
										onCheckedChange={onChange}
									/>
									<p
										className={
											'text-xs text-left font-bahnschrift ' +
											(errors.termsPrivacyPolicy
												? 'text-error'
												: 'text-custom-gray2')
										}
									>
										Aceito a{' '}
										<span
											className="underline cursor-pointer"
											onClick={() => {
												localStorage.setItem(
													'goToTerms',
													'true'
												);
												navigate('/privacypolicy');
											}}
										>
											política de privacidade
										</span>
									</p>
								</div>
							)}
						/>
						<div className="flex gap-2">
							<Button
								variant="destructive"
								onClick={() => setActiveStep(step - 1)}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Voltar
							</Button>
							<Button
								type="submit"
								isLoading={isLoading}
								className="px-3.5 py-1.5 mt-3 font-bahnschrift text-center float-left"
								textCustom="text-sm"
							>
								Confirmar
							</Button>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="overflow-y-auto lg:overflow-hidden max-h-[70vh]">
			<p className="text-2xl font-bold text-left text-custom-gray2 pb-5 font-bahnschrift">
				Abra sua conta
			</p>
			<form onSubmit={handleSubmit(handleRegister)} className="mb-7">
				<ol className="relative mx-4">
					{steps.map((item, index) => (
						<li key={index} className="relative flex flex-col">
							<div className="flex items-start relative">
								<div className="flex flex-col items-center -start-4">
									<span
										className={`absolute z-10 flex items-center justify-center font-bahnschrift w-7 h-7 rounded-full ring-custom-white ${
											item.step <= activeStep
												? 'bg-primary text-custom-white'
												: 'bg-custom-gray5 text-custom-gray2'
										}`}
									>
										{item.step < activeStep ? (
											<RiCheckLine
												size={20}
												color="#ffffff"
												className=""
											/>
										) : (
											item.step
										)}
									</span>
									<div
										className={`text-gray-500 flex border-s w-[1px] absolute ${
											item.step < activeStep
												? 'border-primary h-full -z-10'
												: activeStep === item.step &&
												  activeStep < steps.length
												? 'border-primary h-full'
												: item.step <=
														steps.length - 1 &&
												  'border-custom-gray5 h-10'
										}`}
									></div>
								</div>

								<div className="flex-1 ml-8 mb-4">
									<h3 className="text-md text-left text-custom-gray2 font-bahnschrift">
										{item.title}
									</h3>
									<div
										className={`${
											item.step !== activeStep
												? 'hidden'
												: ''
										}`}
									>
										{stepForm(item.step)}
									</div>
								</div>
							</div>
						</li>
					))}
				</ol>
			</form>
			<div className="flex flex-row justify-between items-center">
				{helpButton()}
				<div className="flex gap-3">
					<a
						onClick={() => {
							navigate('/termsofuse');
						}}
						className="text-xs text-center underline text-primary cursor-pointer font-bahnschrift"
					>
						Termos de Uso
					</a>
					<a
						onClick={() => {
							navigate('/privacypolicy');
						}}
						className="text-xs text-center underline text-primary cursor-pointer font-bahnschrift"
					>
						Política de Privacidade
					</a>
				</div>
			</div>

			<Modal
				id="mvpAlert"
				open={openMVP}
				onClose={() => setOpenMVP(!openMVP)}
			>
				<div className="flex flex-col gap-6 items-center">
					<Warning />

					<p className="text-2xl font-bold text-center w-96 text-custom-gray2 font-bahnschrift">
						Nosso app está quase pronto. Avisaremos você na estreia!
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						Quase lá! <br />
						Assim que estivermos prontos para começar os torneios,
						avisaremos você por e-mail.
					</p>
					<p className="text-sm text-center text-custom-gray2 font-bahnschrift">
						Bem-vindo ao time. Nos vemos em breve.
					</p>
					<p className="text-sm font-bold text-center text-custom-gray2 font-bahnschrift">
						FUTPro, o fantasy para quem é pro em esportes.
					</p>

					<div className="w-full pt-2 flex flex-row gap-2">
						<Button
							onClick={() => {
								setOpenMVP(!openMVP);
							}}
							variant="destructive"
							className={'w-full p-3'}
							isLoading={isLoading}
						>
							Fechar
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default RegisterForms;
