import { ptBR } from 'date-fns/locale/pt-BR';
import TextAreaInput from '../../../../components/ui/Textarea';
import TextInput from '../../../../components/ui/TextInput';
import { useUserProfileStore } from '../../../../store/user';
import { SendDocument } from '../ProfileInfo/Edit/Document';
import { format } from 'date-fns';
import { useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useTokenStore } from '../../../../store/token';
import { toast } from 'sonner';
import Toast from '../../../../components/ui/toast';
import { sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';

export const DocumentsInfo = () => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const userData = useUserProfileStore((state) => state.userData);
	let dateformat;
	const [isLoadingUrl, setIsLoadingUrl] = useState(false);

	if (userData?.birthAt) {
		const birthAt = new Date(userData?.birthAt || '' + 'T00:00:00');
		dateformat = format(birthAt, 'dd/MM/yyyy');
	}

	const requestUrl = async (file: string) => {
		if (isLoadingUrl) return;
		setIsLoadingUrl(true);
		try {
			const body = {
				objectKey: file,
			};
			const res = await api.generateUrl(body, token);

			if (!res?.success) {
				throw new Error(res.message);
			}
			const link = document.createElement('a');
			link.href = res.url;
			link.setAttribute('download', file);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoadingUrl(false);
		}
	};

	return (
		<div className="flex flex-col gap-6 pb-6">
			{userData?.name && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="Nome"
						value={userData?.name}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.documentNumber && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="CPF"
						value={userData?.documentNumber || ''}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.birthAt && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="Data de nascimento"
						value={userData?.birthAt ? dateformat : ''}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.address && (
				<div className="flex-1">
					<TextAreaInput
						placeholder="Endereço"
						value={userData?.address || ''}
						disabled
					/>
				</div>
			)}

			<SendDocument tabsDocuments />

			{userData?.documents && userData?.documents?.length > 0 && (
				<div>
					<p className="text-xs font-semibold text-left text-custom-gray2 pb-2 font-bahnschrift">
						Documentos enviados
					</p>
					<div className="flex flex-col gap-4">
						{userData?.documents.map((item, index) => (
							<div
								onClick={() => requestUrl(item.file)}
								key={index}
								className={
									'w-full cursor-pointer py-3 px-4 bg-custom-gray7 border border-custom-gray4 border-dashed flex flex-col ' +
									(item.status === 'Rejeitado'
										? 'rounded-3xl gap-2'
										: 'rounded-[37px]')
								}
							>
								<div className="flex flex-row items-center justify-between">
									<p className="text-xs text-left text-custom-gray2 font-bahnschrift">
										Enviado em{' '}
										{format(
											item.dateSend,
											'dd/MM/yyyy | HH:mm',
											{
												locale: ptBR,
											}
										)}
									</p>
									<div
										className={
											'flex justify-center items-center gap-1 px-2 py-1 rounded-[36px] ' +
											(item.status === 'Validado'
												? 'bg-success'
												: item.status === 'Rejeitado'
												? 'bg-error'
												: 'bg-custom-gray6')
										}
									>
										<p
											className={
												'flex-grow-0 flex-shrink-0 text-xs font-semibold text-left font-bahnschrift ' +
												(item.status === 'Validado'
													? 'text-custom-white'
													: item.status ===
													  'Rejeitado'
													? 'text-custom-white'
													: 'text-custom-gray2')
											}
										>
											{item.status}
										</p>
									</div>
								</div>
								{item.status === 'Rejeitado' && (
									<div className="flex flex-col gap-1">
										<p className="text-xs text-left text-custom-gray2 font-bahnschrift">
											Motivo e instruções
										</p>

										<p className="flex-grow-0 flex-shrink-0 text-xs font-semibold text-left font-bahnschrift">
											{item.reason}
										</p>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
