import { RiCheckLine } from '@remixicon/react';

const CheckboxCustomIcon = ({ checked, onCheckedChange }: any) => {
	return (
		<div
			className={
				'flex items-center p-0.5 cursor-pointer  rounded-[5.33px] ' +
				(checked ? 'bg-[#2c2c2c]' : 'border border-[#757575]')
			}
		>
			<label>
				<input
					type="checkbox"
					checked={checked}
					onChange={onCheckedChange}
					className="hidden"
				/>
				<div className="flex items-center justify-center w-3.5 h-3.5">
					{checked && <RiCheckLine size={14} color="#ffffff" />}
				</div>
			</label>
		</div>
	);
};

export default CheckboxCustomIcon;
