import * as Sentry from '@sentry/capacitor';
import { Capacitor } from '@capacitor/core';
import numeral from 'numeral';
import 'numeral/locales/pt-br';

export const sentryCapture = (
	error: string,
	customTag: string,
	levelError: Sentry.SeverityLevel
) => {
	Sentry.setTag('my-tag', customTag);
	Sentry.captureException(new Error(error));
};

export const headerHeight = () => {
	if (Capacitor.isNativePlatform()) {
		const platform = Capacitor.getPlatform();
		if (platform === 'ios') {
			return 'pt-12';
		} else {
			return 'pt-2';
		}
	} else {
		return 'pt-2';
	}
};

export const darkModeByRoute = (routeName: string) => {
	const routesBackgroundDark = ['/help', '/profile'];

	if (routesBackgroundDark.includes(routeName)) {
		return true;
	}
	return false;
};

export const validateUserName = (username: string) => {
	const maskPattern = /^@[a-zA-Z0-9]*$/;
	const maskedUsername = username.startsWith('@')
		? username.slice(1)
		: username;
	if (!maskPattern.test(username)) {
		return false;
	}
	if (maskedUsername.length <= 3 || maskedUsername.length > 15) {
		return false;
	}

	if (/[^a-zA-Z0-9]/.test(maskedUsername)) {
		return false;
	}
	return true;
};

export const getErrorMessage = (error: any) => {
	if (error.response) {
		console.error('Data:', error.response.data);
		return error.response.data.message || 'Erro no servidor';
	} else if (error.request) {
		console.error('Request:', error.request);
		return 'Erro de conexão!';
	} else {
		console.error('Error Message:', error.message);
		return error.message;
	}
};

export const formatBalance = (value: number) => {
	return value * -1;
};

export const countRealTime = (finishAt: any) => {
	const start = new Date();
	const finish = new Date(finishAt);
	const diffMs = finish.getTime() - start.getTime();

	if (diffMs <= 0) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			isFinished: true,
		};
	}
	const diffSecs = Math.floor(diffMs / 1000);
	const days = Math.floor(diffSecs / (3600 * 24));
	const hours = Math.floor((diffSecs % (3600 * 24)) / 3600);
	const minutes = Math.floor((diffSecs % 3600) / 60);
	const seconds = diffSecs % 60;

	return { days, hours, minutes, seconds, isFinished: false };
};

export const abbreviateAward = (award: number) => {
	const suffixes = ['', 'k', 'mi', 'bi'];
	let index = 0;

	while (award >= 1000 && index < suffixes.length - 1) {
		award /= 1000;
		index++;
	}

	return award.toFixed() + suffixes[index];
};

export const areMandatoryQuestionsAnswered = (options: any, selected: any) => {
	const mandatoryOptions = options.filter((option: any) => option.mandatory);
	const mandatoryOptionIds = mandatoryOptions.map((option: any) => option.id);
	const selectedOptionIds = selected.map(
		(question: any) => question.optionId
	);
	return mandatoryOptionIds.every((id: any) =>
		selectedOptionIds.includes(id)
	);
};

export const transformInitial = (name: string) => {
	let clearName = name.replace(/^@/, '');

	return clearName.slice(0, 2).toUpperCase();
};

export const generatePrivateKey = () => {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const size = Math.floor(Math.random() * 6) + 25;
	let result = '';

	for (let i = 0; i < size; i++) {
		const index = Math.floor(Math.random() * characters.length);
		result += characters[index];
	}

	return result;
};
export const CurrencyFormatter = (entryAmt: any) => {
	numeral.locale('pt-br');

	return numeral(entryAmt).format('0,0.00');
};

export const generateUrl = (title: string, message: string) => {
	switch (title) {
		case 'Linkedin':
			return `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
				message
			)}`;
		case 'Instagram':
			return 'https://www.instagram.com';
		case 'Whatsapp':
			return `https://api.whatsapp.com/send/?text=${encodeURIComponent(
				message
			)}&type=custom_url&app_absent=0`;
		case 'Facebook':
			return `
			https://www.facebook.com/share_channel/?type=reshare&link=www.futpro.com.br&app_id=966242223397117&source_surface=external_reshare`;
		case 'X':
			return `https://x.com/intent/post?text=${encodeURIComponent(
				message
			)}`;
		default:
			return '';
	}
};

export const validateKeyPix = (key: string) => {
	key = key.replace(/[^\w]/g, '');

	if (key.length !== 32) {
		return false;
	}

	const defaultKeyPix =
		/^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
	if (defaultKeyPix.test(key)) {
		return false;
	}

	return true;
};

export const showWalletAndBalance = () => {
	if (Capacitor.getPlatform() === 'android') {
		return false;
	} else {
		return true;
	}
};
