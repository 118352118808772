import { RiEyeFill, RiEyeOffFill } from '@remixicon/react';
import { useBalanceStore } from '../../store/balance';

const HideBalance = () => {
	const hideBalance = useBalanceStore((state) => state.hideBalance);

	return (
		<button type="button">
			{hideBalance ? (
				<RiEyeFill size={20} color="#FFFFFF" className="ml-2" />
			) : (
				<RiEyeOffFill size={20} color="#FFFFFF" className="ml-2" />
			)}
		</button>
	);
};

export default HideBalance;
