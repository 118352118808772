export const Smile = () => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="smile">
				<path
					id="Vector"
					d="M9 16.75C13.1421 16.75 16.5 13.3921 16.5 9.25C16.5 5.10786 13.1421 1.75 9 1.75C4.85786 1.75 1.5 5.10786 1.5 9.25C1.5 13.3921 4.85786 16.75 9 16.75Z"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_2"
					d="M6 10.75C6 10.75 7.125 12.25 9 12.25C10.875 12.25 12 10.75 12 10.75"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_3"
					d="M6.75 7H6.7575"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					id="Vector_4"
					d="M11.25 7H11.2575"
					stroke="black"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};
