import { useLayoutEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { permissionRoles, SentryError } from '../../types/enums';
import { useTokenGetStreamStore, useTokenStore } from '../../store/token';
import { useBalanceStore } from '../../store/balance';
import { AuthContext } from './AuthContext';
import { useUserProfileStore, useUserStore } from '../../store/user';
import { useApi } from '../../hooks/useApi';
import Toast from '../../components/ui/toast';
import { formatBalance, sentryCapture } from '../../helpers/utils';
import { usePromoContext } from '../PromoCode/PromoContext';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import OneSignalWeb from 'react-onesignal';

declare global {
	interface Window {
		zE?: any;
	}
}

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
	const api = useApi();
	const navigate = useNavigate();
	const { promocode } = usePromoContext();
	const user = useUserStore((state) => state.user);
	const { saveUser, clearUser } = useUserStore();
	const { saveBalance, clearBalance } = useBalanceStore();
	const { clearUserData, saveUserData, updateUserData } =
		useUserProfileStore();
	const token = useTokenStore((state: any) => state.token);
	const saveToken = useTokenStore((state: any) => state.saveToken);
	const clearToken = useTokenStore((state: any) => state.clearToken);
	const saveGetStreamToken = useTokenGetStreamStore(
		(state: any) => state.saveTokenGetStream
	);
	const clearGetStreamToken = useTokenGetStreamStore(
		(state: any) => state.clearTokenGetStream
	);

	const checkTokenExpiration = async () => {
		if (token) {
			try {
				const decoded = jwtDecode(token) as any;
				const expirationTime = decoded.exp * 1000;
				const currentTime = Date.now();

				if (currentTime >= expirationTime) {
					signOut();
				}
			} catch (error) {
				console.error('Erro ao decodificar o token:', error);
				signOut();
			}
		}
	};

	const tokenExpirationCheckRef = useRef<NodeJS.Timeout | null>(null);

	useLayoutEffect(() => {
		if (token) {
			checkTokenExpiration();
			const interval = setInterval(checkTokenExpiration, 3600000);
			tokenExpirationCheckRef.current = interval;
		} else {
			if (tokenExpirationCheckRef.current) {
				clearInterval(tokenExpirationCheckRef.current);
				tokenExpirationCheckRef.current = null;
			}
		}

		return () => {
			if (tokenExpirationCheckRef.current) {
				clearInterval(tokenExpirationCheckRef.current);
				tokenExpirationCheckRef.current = null;
			}
		};
	}, [token]);

	const signIn = async (login: string, password: string) => {
		try {
			const data = await api.signIn(login, password, promocode);

			if (data.success && data.token) {
				const decoded = jwtDecode(data.token) as any;
				const expirationTime = decoded.exp * 1000;
				const currentTime = Date.now();
				if (currentTime >= expirationTime) {
					return {
						success: false,
						message: 'Token expirado. Faça login novamente.',
					};
				}

				const userRoles = Array.isArray(decoded.roles)
					? decoded.roles
					: [decoded.roles];
				const hasRoles = userRoles.some((role: any) =>
					permissionRoles.includes(role)
				);

				const isAffiliate = userRoles.includes(2);

				if (hasRoles) {
					const user = {
						accountId: decoded.accountId,
						username: decoded.nick,
						permisson: isAffiliate ? 'affiliate' : 'player',
					};
					const getProfile = await api.getProfile(data.token);
					if (!getProfile?.success) {
						throw new Error(getProfile.message);
					}
					saveUserData(getProfile.data);
					saveToken(data.token);
					saveGetStreamToken(decoded.getStreamToken);
					saveUser(user);
					saveBalance(
						formatBalance(decoded.currentBalance).toFixed(2)
					);

					if (Capacitor.isNativePlatform()) {
						OneSignal.login(decoded.accountId);
						OneSignal.User.addTags({
							accountId: decoded.accountId,
							logged: true,
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					} else {
						await OneSignalWeb.login(decoded.accountId);
						OneSignalWeb.User.addTags({
							accountId: decoded.accountId,
							logged: 'true',
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					}

					if (!tokenExpirationCheckRef.current) {
						checkTokenExpiration();
						const interval = setInterval(
							checkTokenExpiration,
							3600000
						);
						tokenExpirationCheckRef.current = interval;
					}

					return data;
				} else {
					return {
						success: false,
						message: 'Entre em contato com o suporte! cod 99',
					};
				}
			} else {
				return {
					success: false,
					message: data.message,
				};
			}
		} catch (error: any) {
			console.error('Erro durante o login:', error);
			return {
				success: false,
				message: error?.message,
			};
		}
	};

	const signInToken = async (email: string, code: string) => {
		try {
			const data = await api.validateTokenLogin(email, code, promocode);

			if (data.success && data.token) {
				const decoded = jwtDecode(data.token) as any;
				const expirationTime = decoded.exp * 1000;
				const currentTime = Date.now();
				if (currentTime >= expirationTime) {
					return {
						success: false,
						message: 'Token expirado. Faça login novamente.',
					};
				}

				const userRoles = Array.isArray(decoded.roles)
					? decoded.roles
					: [decoded.roles];
				const hasRoles = userRoles.some((role: any) =>
					permissionRoles.includes(role)
				);

				const isAffiliate = userRoles.includes(2);

				if (hasRoles) {
					const user = {
						accountId: decoded.accountId,
						username: decoded.nick,
						permisson: isAffiliate ? 'affiliate' : 'player',
					};
					const getProfile = await api.getProfile(data.token);
					if (!getProfile?.success) {
						throw new Error(getProfile.message);
					}
					saveUserData(getProfile.data);
					saveToken(data.token);
					saveGetStreamToken(decoded.getStreamToken);
					saveUser(user);
					saveBalance(
						formatBalance(decoded.currentBalance).toFixed(2)
					);

					if (Capacitor.isNativePlatform()) {
						OneSignal.login(decoded.accountId);
						OneSignal.User.addTags({
							accountId: decoded.accountId,
							logged: true,
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					} else {
						await OneSignalWeb.login(decoded.accountId);
						OneSignalWeb.User.addTags({
							accountId: decoded.accountId,
							logged: 'true',
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					}

					if (!tokenExpirationCheckRef.current) {
						checkTokenExpiration();
						const interval = setInterval(
							checkTokenExpiration,
							3600000
						);
						tokenExpirationCheckRef.current = interval;
					}

					toast(
						<Toast variant="success">
							Login efetuado com sucesso.
						</Toast>
					);
					return data;
				} else {
					return {
						success: false,
						message: data.message,
					};
				}
			} else {
				return {
					success: false,
					message:
						'Credenciais inválidas. Verifique seu usuário e senha.',
				};
			}
		} catch (error: any) {
			console.error('Erro durante o login:', error);
			return {
				success: false,
				message: error.message,
			};
		}
	};

	const register = async (body: any) => {
		try {
			const data = await api.register({
				...body,
				email: body.email.toLowerCase(),
				promocode,
			});

			if (data.success && data.token) {
				const decoded = jwtDecode(data.token) as any;
				const expirationTime = decoded.exp * 1000;
				const currentTime = Date.now();
				if (currentTime >= expirationTime) {
					return {
						success: false,
						message: 'Token expirado. Faça login novamente.',
					};
				}

				const userRoles = Array.isArray(decoded.roles)
					? decoded.roles
					: [decoded.roles];
				const hasRoles = userRoles.some((role: any) =>
					permissionRoles.includes(role)
				);
				const isAffiliate = userRoles.includes(2);

				if (hasRoles) {
					const user = {
						accountId: decoded.accountId,
						username: decoded.nick,
						permisson: isAffiliate ? 'affiliate' : 'player',
					};
					const getProfile = await api.getProfile(data.token);
					if (!getProfile?.success) {
						throw new Error(getProfile.message);
					}
					saveUserData(getProfile.data);
					saveToken(data.token);
					saveGetStreamToken(decoded.getStreamToken);
					saveUser(user);
					saveBalance(
						formatBalance(decoded.currentBalance).toFixed(2)
					);

					localStorage.removeItem('registerFormData');
					localStorage.removeItem('registerFormStep');

					if (Capacitor.isNativePlatform()) {
						OneSignal.login(decoded.accountId);
						OneSignal.User.addTags({
							accountId: decoded.accountId,
							logged: true,
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					} else {
						await OneSignalWeb.login(decoded.accountId);
						OneSignalWeb.User.addTags({
							accountId: decoded.accountId,
							logged: 'true',
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					}

					if (!tokenExpirationCheckRef.current) {
						checkTokenExpiration();
						const interval = setInterval(
							checkTokenExpiration,
							3600000
						);
						tokenExpirationCheckRef.current = interval;
					}
					toast(
						<Toast variant="success">
							Login efetuado com sucesso.
						</Toast>
					);
					return data;
				} else {
					return {
						success: false,
						message: data.message,
					};
				}
			} else {
				return {
					success: false,
					message: data.message,
				};
			}
		} catch (error: any) {
			console.error('Erro durante o login:', error);
			return {
				success: false,
				message: error.message,
			};
		}
	};

	const forgotPass = async (body: any) => {
		try {
			const data = await api.updatePasswordByEmail({
				...body,
				promocode,
			});

			if (data.success && data.token) {
				const decoded = jwtDecode(data.token) as any;
				const expirationTime = decoded.exp * 1000;
				const currentTime = Date.now();
				if (currentTime >= expirationTime) {
					return {
						success: false,
						message: 'Token expirado. Faça login novamente.',
					};
				}

				const userRoles = Array.isArray(decoded.roles)
					? decoded.roles
					: [decoded.roles];
				const hasRoles = userRoles.some((role: any) =>
					permissionRoles.includes(role)
				);
				const isAffiliate = userRoles.includes(2);

				if (hasRoles) {
					const user = {
						accountId: decoded.accountId,
						username: decoded.nick,
						permisson: isAffiliate ? 'affiliate' : 'player',
					};
					const getProfile = await api.getProfile(data.token);
					if (!getProfile?.success) {
						throw new Error(getProfile.message);
					}
					saveUserData(getProfile.data);
					saveToken(data.token);
					saveGetStreamToken(decoded.getStreamToken);
					saveUser(user);
					saveBalance(
						formatBalance(decoded.currentBalance).toFixed(2)
					);

					if (Capacitor.isNativePlatform()) {
						OneSignal.login(decoded.accountId);
						OneSignal.User.addTags({
							accountId: decoded.accountId,
							logged: true,
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					} else {
						await OneSignalWeb.login(decoded.accountId);
						OneSignalWeb.User.addTags({
							accountId: decoded.accountId,
							logged: 'true',
							permission: user.permisson,
							affiliateId: getProfile.data.affiliateId,
						});
					}

					if (!tokenExpirationCheckRef.current) {
						checkTokenExpiration();
						const interval = setInterval(
							checkTokenExpiration,
							3600000
						);
						tokenExpirationCheckRef.current = interval;
					}
					toast(
						<Toast variant="success">
							Login efetuado com sucesso.
						</Toast>
					);
					return data;
				} else {
					return {
						success: false,
						message: data.message,
					};
				}
			} else {
				return {
					success: false,
					message: data.message,
				};
			}
		} catch (error: any) {
			console.error('Erro:', error);
			return {
				success: false,
				message: error.message,
			};
		}
	};

	const signOut = async () => {
		try {
			if (Capacitor.isNativePlatform()) {
				OneSignal.User.addTags({ logged: false });
			} else {
				OneSignalWeb.User.addTags({ logged: 'false' });
			}

			// window.zE('messenger', 'logoutUser');
			clearUser();
			clearToken();
			clearGetStreamToken();
			clearBalance();
			clearUserData();
			navigate('/');
		} catch (error) {
			console.error('Erro durante o logout:', error);
		}
	};

	const updateProfile = async () => {
		try {
			const getProfile = await api.getProfile(token);
			if (!getProfile?.success) {
				throw new Error(getProfile.message);
			}
			saveUserData(getProfile.data);
			saveBalance(
				formatBalance(getProfile.data.currentBalance).toFixed(2)
			);
			const isValidDocument = getProfile.data.documents.some(
				(doc: any) => doc.status === 'Validado'
			);
			updateUserData('validDocumentExists', isValidDocument);
		} catch (error: any) {
			sentryCapture(error, 'updateProfile', SentryError.Error);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				signIn,
				signOut,
				signInToken,
				register,
				forgotPass,
				updateProfile,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
